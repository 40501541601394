import makeStyles from "@material-ui/core/styles/makeStyles";

import { backgroundGray } from "../constants";

const Background: React.FC = () => {

	const classes = useStyles();

	return (
		<div className={classes.stuff} />
	);
}

export default Background;

const useStyles = makeStyles(theme => ({
	stuff: {
		position: 'fixed',
		zIndex: -29,
		width: '100vw',
		height: '100vh',
		backgroundColor: backgroundGray,
		backgroundImage: `url('/images/svg/background.svg')`,
		backgroundSize: '60%',
		backgroundPosition: 'left center',
		[theme.breakpoints.down(600)]: {
			backgroundSize: '200%',
		}
	}
}));