import makeStyles from "@material-ui/core/styles/makeStyles";
import clsx from 'clsx';

import { Title } from "..";

interface ContainerProps {
	title: string;
	subTitle: string;
	largeMargins?: boolean;
}


const Container: React.FC<ContainerProps> = ({ title, subTitle, largeMargins = false, children }) => {

	const classes = useStyles();

	return (
		<div className={clsx(classes.container, largeMargins && classes.largeMargins)}>
			<Title
				title={title}
				subTitle={subTitle}
				containerClass={classes.title}
			/>

			{children}
		</div>
	);
}

export default Container;

export const useStyles = makeStyles(theme => ({
	container: {
		padding: '4vh 7vw 8vh',
		[theme.breakpoints.up(600)]: {
			width: '52vw',
			margin: 'auto'
		}
	},
	title: {
		width: '60vw',
		margin: '0 auto',
		[theme.breakpoints.up(600)]: {
			width: '100%'
		}
	},
	largeMargins: {
		padding: '4vh 11vw 8vh',
	}
}))