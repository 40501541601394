import clsx from 'clsx';

interface ButtonLoadingProps {
    greenLoading: boolean;
}

const ButtonLoading: React.FC<ButtonLoadingProps> = ({ greenLoading }) => (
    <div className={clsx("spinner", greenLoading && 'spinner--green')}>
        {[...Array(3)].map((_, index) => (
            <div key={index} className={`bounce${index + 1}`} />
        ))}
    </div>
);

export default ButtonLoading;