import { useEffect } from 'react';
import { useForm } from '@hilma/forms';
import axios from 'axios';

import { useGetOptions, useReject, useSetDisableNext, useSomeSelected, useUpdateSaved, useUpdateSubmit } from "../../hooks";
import { FormCheckboxList, Container } from '..';
import { daysOfWeek } from '../../constants';
import { DaysOfWeekObj } from "../../types";


const Workdays: React.FC = () => {

	const options = useGetOptions(DaysOfWeekObj, daysOfWeek, 'daysOfWeek');
	const form = useForm();
	const setDisableNext = useSetDisableNext();
	const someSelected = useSomeSelected(form.values.daysOfWeek);
	const reject = useReject();
	const updateSaved = useUpdateSaved();

	const onSubmit = async () => {
		await form.setFieldValue('requiredValues', ['address', 'radius', 'daysOfWeek']);

		form.handleSubmit();

		try {

			await axios.post('/api/candidate-pref/save-candidate-workdays', { workdays: form.values.daysOfWeek });

			await updateSaved();

		} catch (err: any) {
			return reject(err);
		}
	}

	useUpdateSubmit(onSubmit);

	useEffect(() => {
		if (someSelected) return setDisableNext(false);
		return setDisableNext(true);
	}, [someSelected]);


	return (
		<Container
			title='ימי עבודה'
			subTitle='אלו ימים פנויים להצעות עבודה?'
		>
			<FormCheckboxList id='daysOfWeek' options={options} />
		</Container>
	);
}

export default Workdays;