import makeStyles from "@material-ui/core/styles/makeStyles";

import { boxShadowGray } from "../../constants";
import { EditButton } from "..";


const CongratsFinished: React.FC = () => {

	const classes = useStyles();

	return (
		<div className={classes.container}>

			<div>
				<h1 className={classes.thanks}>תודה!</h1>
				<h2 className={classes.sentBanner}>בקשתך נשלחה בהצלחה!</h2>
			</div>

			<p className={classes.explanation}>המערכת שלנו תעבור על בקשתך <br />בימים הקרובים תקבל הודעת אישור על הצטרפותך למערכת</p>

			<EditButton text='עריכת בקשה' />

		</div>
	);
}

export default CongratsFinished;

const useStyles = makeStyles(theme => ({
	container: {
		height: 'calc(100vh - 133px)',
		padding: '4vh 0 10vh',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'space-between',
		textAlign: 'center',
		color: theme.palette.primary.main,
		boxSizing: 'border-box',
		overflow: 'hidden',
	},
	thanks: {
		fontSize: '2.2rem'
	},
	sentBanner: {
		backgroundColor: '#D0F4DE',
		boxShadow: `0 3px 6px ${boxShadowGray}`,
		transform: 'rotate(3deg)',
		padding: '3px 6px'
	},
	explanation: {
		width: '77vw',
		fontSize: '1.4rem'
	}
}));