import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import { Link } from 'react-router-dom';

const AccessibilityLink = () => {

    const classes = useStyles();

    return (
        <div className={clsx('Accessibility-link', classes.container)}>
            <Link to={"/Accessibility"}>הצהרת נגישות</Link >
        </div>
    )
}

export default AccessibilityLink;

const useStyles = makeStyles({
    container: {
        position: 'fixed',
        zIndex: 200,
        bottom: 0,
        left: 0,
        padding: 3,
        background: 'white'
    }
})