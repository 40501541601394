import { useEffect } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { PrivateRoute } from "@hilma/auth";
import { provide } from "@hilma/tools";
import axios from "axios";

import { PersonalDetails, PageNotFound, Welcome } from '.';
import { CandidateStatusAndNameProvider } from "../context";
import { useCandidateStatus } from "../hooks";
import { UserStatus } from "../types";

import ListOfRequests from "./ListOfRequests/ListOfRequests";
import ContactUsForm from "../components/ContactUs";

const Home: React.FC = () => {
  const candidateStatus = useCandidateStatus();

  useEffect(() => {
    const isLoggedIn = sessionStorage.getItem('loggedIn');
    // if (isLoggedIn) return;
    try {
      axios.post('/api/fact-logins/insertNewLogin');
    } catch (err) { console.log(err) }
    // sessionStorage.setItem('loggedIn', 'true');
  }, [])

  if ([UserStatus.InProgress, UserStatus.Deleted].includes(candidateStatus))
    <Navigate to="/personal-details" replace />;

  return (
    <Routes>
      <Route path="/" element={<Welcome />} />
      <Route path="/personal-details">
        <Route index element={<PrivateRoute component={PersonalDetails} componentName="PersonalDetails" redirectPath="/sign-in" />} />
        <Route path=":hideInstructions" element={<PrivateRoute component={PersonalDetails} componentName="PersonalDetails" redirectPath="/sign-in" />} />
      </Route>
      <Route path="/list-of-requests" element={<PrivateRoute component={ListOfRequests} componentName="PersonalDetails" redirectPath="/sign-in" />} />
      <Route path="/contactUs" element={<PrivateRoute component={ContactUsForm} componentName="ContactUs" redirectPath="/sign-in" />} />
      <Route path="/*" element={<PageNotFound />} />
    </Routes>
  );
};

export default provide(CandidateStatusAndNameProvider)(Home);