import { useEffect } from 'react';
import { useForm } from '@hilma/forms';
import axios from 'axios';

import { useGetOptions, useReject, useSetDisableNext, useSomeSelected, useUpdateSubmit, useUpdateSaved } from '../../hooks';
import { sectors as sectorsObj } from '../../constants';
import { Container, FormCheckboxList } from '..';
import { Sector as Sect } from "../../types";


const Sector: React.FC = () => {

	const options = useGetOptions(Sect, sectorsObj, 'sectors');
	const form = useForm();
	const setDisableNext = useSetDisableNext();
	const someSelected = useSomeSelected(form.values.sectors);
	const reject = useReject();
	const updateSaved = useUpdateSaved();

	const onSubmit = async () => {
		await form.setFieldValue('requiredValues', ['address', 'radius', 'daysOfWeek', 'sectors']);

		form.handleSubmit();

		try {

			await axios.post('/api/candidate-pref/save-candidate-sectors', { sectors: form.values.sectors });

			await updateSaved();

		} catch (err: any) {
			return reject(err);
		}
	}

	useUpdateSubmit(onSubmit);


	useEffect(() => {
		if (someSelected) return setDisableNext(false);
		return setDisableNext(true);
	}, [someSelected]);

	return (
		<Container
			title='אוכלוסייה'
			subTitle='באילו מגזרים מתאים לך לעבוד?'
		>
			<FormCheckboxList id='sectors' options={options} />
		</Container>
	);
}

export default Sector;