import React, { createContext, useCallback, useRef, useState } from 'react';
import { useAsyncState, wrap } from '@hilma/tools';
import { produce } from 'immer';

import { detailsSteps } from '../constants';
import { StateStep } from '../types';

export const StepsContext = createContext<StateStep[] | null>(null);
export const ActiveStepContext = createContext<number | null>(null);
export const UpdateActiveStepContext = createContext<((newStepGenerator: () => Promise<number>, shouldSubmit?: boolean) => Promise<void>) | null>(null);
export const HandleNextContext = createContext<(() => Promise<void>) | null>(null);
export const HandlePreviousContext = createContext<(() => void) | null>(null);
export const SubmitRefContext = createContext<React.MutableRefObject<(() => Promise<void>) | null> | null>(null);
export const UpdateSavedContext = createContext<((stepToChange?: number, visible?: boolean) => Promise<void>) | null>(null);
export const NextButtonTargetContext = createContext<[HTMLButtonElement | null, React.Dispatch<React.SetStateAction<HTMLButtonElement | null>>] | null>(null);
export const DetailsLoadingContext = createContext<boolean | null>(null);
export const SetDetailsLoadingContext = createContext<React.Dispatch<React.SetStateAction<boolean>> | null>(null);
export const ShowWorkRegionContext = createContext<boolean | null>(null);
export const DisableNextContext = createContext<boolean | null>(null);
export const SetDisableNextContext = createContext<React.Dispatch<React.SetStateAction<boolean>> | null>(null);
export const CanShowCongratsFinishedContext = createContext<boolean | null>(null);
export const SetCanShowCongratsFinishedContext = createContext<React.Dispatch<React.SetStateAction<boolean>> | null>(null);
export const UpdateVisitedContext = createContext<((step: number) => void) | null>(null);

const StepsProvider: React.FC = ({ children }) => {

    const nextButtonTargetTuple = useState<HTMLButtonElement | null>(null);
    const [detailsLoading, setDetailsLoading] = useAsyncState(false);
    const [activeStep, setActiveStep, getActiveStep] = useAsyncState(0);
    const [disableNext, setDisableNext] = useState(false);
    const [showWorkRegion, setShowWorkRegion, getShowWorkRegion] = useAsyncState(false);
    const [canShowCongratsFinished, setCanShowCongratsFinished, getCanShowCongratsFinished] = useAsyncState(false);


    const [steps, setSteps, getSteps] = useAsyncState<StateStep[]>(detailsSteps.map((_, index) => ({
        saved: false,
        visited: index === 0,
    })));
    const submitRef = useRef<((isBackButton?: boolean) => Promise<void>) | null>(null);

    const updateActiveStep = useCallback(async (newStepGenerator: () => Promise<number>, shouldSubmit = false, isBackButton = false) => {
        try {
            await setDetailsLoading(true);
            const activeStep = await getActiveStep();
            const initialSteps = await getSteps();
            const canShowCongratsFinished = getCanShowCongratsFinished();

            if ((initialSteps[activeStep].saved || shouldSubmit) && submitRef.current)
                await submitRef.current(isBackButton);


            const step = await newStepGenerator();


            if (!(step in steps)) return;

            await Promise.all([
                setSteps(produce((steps: StateStep[]) => {
                    steps[step].visited = true;
                })),
                setActiveStep(step)
            ]);

        } catch (error) {
            console.error('error: ', error);
        }
        finally {
            await setDetailsLoading(false);
        }
    }, []);

    const updateVisited = (step: number) => {
        setSteps(produce((steps: StateStep[]) => {
            steps[step].visited = true;
            steps[step].saved = true;
        }))
    }

    const handleNext = useCallback(async () => {
        const newStepGenerator = async () => {
            const activeStep = await getActiveStep();

            const steps = await getSteps();

            let nextStep = activeStep + 1;

            for (let index = activeStep + 1; index < steps.length; index++) {
                nextStep = index;
                break;
            }

            return nextStep;
        }

        return updateActiveStep(newStepGenerator, true);
    }, [updateActiveStep]);

    const handlePrevious = useCallback(async () => {

        const newStepGenerator = async () => {
            const activeStep = await getActiveStep();
            const steps = await getSteps();

            let previousStep = activeStep - 1;

            for (let index = activeStep - 1; index >= 0; index--) {
                previousStep = index;
                break;
            }


            return previousStep;
        }

        return updateActiveStep(newStepGenerator, false, true);
    }, [updateActiveStep]);

    const updateSaved = useCallback(async (stepToChange?: number) => {
        const activeStep = await getActiveStep();
        await setSteps(produce((steps: StateStep[]) => {
            steps[activeStep].saved = true;
            if (
                stepToChange !== undefined &&
                steps.map(step => step.visited).lastIndexOf(true) > stepToChange
            ) steps[stepToChange].saved = true;
        }));
    }, []);

    return (
        <StepsContext.Provider value={steps}>
            <ActiveStepContext.Provider value={activeStep}>
                <UpdateActiveStepContext.Provider value={updateActiveStep}>
                    <HandleNextContext.Provider value={handleNext}>
                        <HandlePreviousContext.Provider value={handlePrevious}>
                            <SubmitRefContext.Provider value={submitRef}>
                                <UpdateSavedContext.Provider value={updateSaved}>
                                    <NextButtonTargetContext.Provider value={nextButtonTargetTuple}>
                                        <DetailsLoadingContext.Provider value={detailsLoading}>
                                            <ShowWorkRegionContext.Provider value={showWorkRegion}>
                                                <DisableNextContext.Provider value={disableNext}>
                                                    <SetDisableNextContext.Provider value={setDisableNext}>
                                                        <SetCanShowCongratsFinishedContext.Provider value={setCanShowCongratsFinished}>
                                                            <CanShowCongratsFinishedContext.Provider value={canShowCongratsFinished}>
                                                                <UpdateVisitedContext.Provider value={updateVisited}>
                                                                    <SetDetailsLoadingContext.Provider value={setDetailsLoading}>
                                                                        {children}
                                                                    </SetDetailsLoadingContext.Provider>
                                                                </UpdateVisitedContext.Provider>
                                                            </CanShowCongratsFinishedContext.Provider>
                                                        </SetCanShowCongratsFinishedContext.Provider>
                                                    </SetDisableNextContext.Provider>
                                                </DisableNextContext.Provider>
                                            </ShowWorkRegionContext.Provider>
                                        </DetailsLoadingContext.Provider>
                                    </NextButtonTargetContext.Provider>
                                </UpdateSavedContext.Provider>
                            </SubmitRefContext.Provider>
                        </HandlePreviousContext.Provider>
                    </HandleNextContext.Provider>
                </UpdateActiveStepContext.Provider>
            </ActiveStepContext.Provider>
        </StepsContext.Provider>
    );
};

export default StepsProvider;