export const errors = {
	generalShort: 'אירעה שגיאה',
	generalSystem: 'הייתה תקלה במערכת',
	googleMapsStation: {
		israelLoc: "הכתובת צריכה להיות בישראל",
		locationNotFound: "הכתובת לא נמצא",
		userExists4station: 'כבר קיימת תחנת חלוקה עבור משתמש זה',
	},
	name: 'השם המלא אינו תקין',
	phone: 'מספר הטלפון אינו תקין',
	email: 'המייל אינו תקין',
	phoneWoOther: 'אנא הכנס מספר טלפון ללא רווחים או תווים אחרים',
	associationExists: 'כבר קיימת עמותה למשתמש זה',
	associationNameExists: 'שם העמותה תפוס',
	selectASet: 'יש לבחור לפחות סט אחד',
	selectAStation: 'יש לבחור תחנה',
	nextSelectAStation: 'יש ללחוץ הבא ולבחור תחנה',
	selectValue: 'יש לבחור סכום',
	numbersOnly: 'יש להזין ספרות בלבד',
	numberTooBig: 'יש להזין מספר מתחת ל 2147483647',
	enterValue: 'יש להזין סכום',
	plsLogin: 'מספר טלפון זה כבר קיים במערכת, נא להתחבר',
	phoneExists: 'מספר טלפון זה כבר קיים במערכת',
	invalidInfo: 'אחד או יותר מהפרטים שהוזנו לא תקינים',
	succSaved: 'הפרטים נשמרו בהצלחה',
	notAllowed: 'פעולה זו אינה מורשה במצב הנוכחי',
	tooHeavy: 'הקבצים שנבחרו גדולים מדי',
	hebrew: 'נא לכתוב בעברית בלבד',
	twoDrivers: 'יש להוסיף ולשמור לפחות שני נהגים',
	driversNotSaved: 'ישנם נהגים שהוספו אך לא נשמרו',
	sucksaved: 'הפרטים נשמרו בהצלחה',
	suckupdated: 'הפרטים עודכנו בהצלחה',
	suckCopied: 'הקישור הועתק בהצלחה',
	purchaseCancelSuccess: "ההזמנה בוטלה בהצלחה",
	tryAgainLater: 'שגיאה. נסה שנית מאוחר יותר',
	purchaseCancelError: 'שגיאה במחיקת הזמנה',
	getDataError: 'שגיאה בהבאת מידע',
	website: 'חייב להתחיל ב //:https או //:http',
	intMax: 'צריך מספר סביר מ20 ומעלה',
	specifyLocation: 'אנא דייק את כתובתך.  נסה להשתמש במילים כמו "רחוב"',
	notEnoughSpaceStation: 'בתחנה שנבחרה אין מספיק מקום, אנא בחר תחנה אחרת',
	canPurchase: 'ניתן לרכוש כרגע',
	unavailableSet: 'אחד או יותר מהסטים שנבחרו אינו זמין, רעננו ונסו שנית',
	noLink: 'הקישור לא נמצא',
	campaignConflict: 'אנא נסו להשתמש במקור, מדיום ו/או תוכן שונים',
	requestDeletedError: 'קריאה זו כבר נמחקה',
	requestAssignedError: 'קריאה זו כבר שובצה',
}