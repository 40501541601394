import withStyles from '@material-ui/core/styles/withStyles';
import Radio, { RadioProps } from '@material-ui/core/Radio';

const RadioButton = withStyles(theme => ({
	root: {
		color: theme.palette.primary.main,
		'&$checked': {
			color: theme.palette.primary.main,
		},
	},
	checked: {},
}))((props: RadioProps) => <Radio color="primary" {...props} />);

export default RadioButton;