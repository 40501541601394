import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';

export const SM = () => {
    const { address } = useParams<{ address: string }>();
    const [value, setValue] = useState<string>("")
    const phoneRegExp = /^[0][1-9]{1}[0-9]{7,8}$/
    const reg = /^\d+$/;
    const emailReg = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

    function sendSpamReq() {
        if (address === "phone") {
            if (!phoneRegExp.test(value)) {
                alert("אנא בדוק את מספר הטלפון")
                return
            }
            axios.post("/api/spam-manager/add-sms-to-spam", { phoneNumber: value })
        }
        else {
            if (!emailReg.test(value)) {
                alert("אנא בדוק את האמייל")
                return
            }
            axios.post("/api/spam-manager/add-email-to-spam", { email: value })
        }
        alert("תודה")


    }

    function set(val: string) {
        if (address === "phone") {
            if ((reg.test(val) && val.length < 11) || val === "") {
                setValue(val)
            }
        } else {
            setValue(val)
        }
    }

    return (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", }}>
            {address === "phone" ?
                <label htmlFor='remove-number/email'>הזן מספר טלפון</label>
                :
                <label htmlFor='remove-number/email'>הזן אמייל</label>
            }
            <input maxLength={address === "phone" ? 13 : 70} value={value} onChange={(e) => set(e.target.value)} name="remove-number/email" />
            <button onClick={sendSpamReq}>שלח</button>
        </div>
    )
}
