import makeStyles from "@material-ui/core/styles/makeStyles";
import clsx from 'clsx';

import { useReusableStyles } from '../hooks';

interface TitleProps {
	title?: string;
	subTitle?: string;
	containerClass?: string;
}

const Title: React.FC<TitleProps> = ({ title, subTitle, containerClass, children }) => {

	const classes = useStyles();
	const resetStyles = useReusableStyles()

	return (
		<div className={clsx(classes.container, containerClass)}>
			<h2 className={clsx(resetStyles.reset, classes.title)}>{children ?? title}</h2>
			<h3 className={resetStyles.reset}>{subTitle}</h3>
		</div>
	);
}

export default Title;

const useStyles = makeStyles(theme => ({
	container: {
		width: '100%',
		textAlign: 'center',
		color: theme.palette.primary.main,
	},
	title: {
		fontSize: '1.3rem',
		fontFamily: 'Assistant-Bold'
	},
}));