import React, { createContext, useCallback, useEffect, useState } from 'react';
import { Option, useAlert } from '@hilma/forms';
import { wrap } from '@hilma/tools';
import axios from 'axios';

export const SubjectsContext = createContext<Option[] | null>(null);
export const GetSubjectsContext = createContext<(() => Promise<void>) | null>(null);

const StepsProvider: React.FC = ({ children }) => {

	const [subjects, setSubjects] = useState<Option[] | null>(null);
	const alert = useAlert();

	const getSubjects = useCallback(async () => {
		try {
			const { data } = await axios.get('/api/subject/get-subjects-as-options')
			setSubjects(data);
		} catch (err) {
			console.error(err);
			alert('שגיאה בהבאת מידע');
		}
	}, []);

	useEffect(() => { getSubjects(); }, []);

	return (
		<SubjectsContext.Provider value={subjects}>
			<GetSubjectsContext.Provider value={getSubjects}>
				{children}
			</GetSubjectsContext.Provider>
		</SubjectsContext.Provider>
	);
};

export default StepsProvider;