import { CandidateEducation, DaysOfWeek, Grade, Language, Sector } from "../types"

export const languages = {
	[Language.Hebrew]: 'עברית',
	[Language.Arabic]: 'ערבית'
}

export const daysOfWeek = {
	[DaysOfWeek.Sunday]: 'ראשון',
	[DaysOfWeek.Monday]: 'שני',
	[DaysOfWeek.Tuesday]: 'שלישי',
	[DaysOfWeek.Wednesday]: 'רביעי',
	[DaysOfWeek.Thursday]: 'חמישי',
	[DaysOfWeek.Friday]: 'שישי',
	[DaysOfWeek.Saturday]: 'שבת',
}

export const sectors: Record<Sector, string> = {
	[Sector.UltraOrthodox]: 'חרדי',
	[Sector.Arab]: 'ערבי',
	[Sector.State]: 'ממלכתי',
	[Sector.StateReligious]: 'ממלכתי-דתי',
}

export const grades: Record<Grade, string> = {
	[Grade.First]: "א-ב",
	[Grade.Second]: "ג-ד",
	[Grade.Third]: "ה-ו",
	[Grade.Fourth]: "ז-ח",
	[Grade.Fifth]: "ט-י",
	[Grade.Sixth]: 'י"א-י"ב',
	// [Grade.Seventh]: "ז",
	// [Grade.Eighth]: "ח",
	// [Grade.Ninth]: "ט",
	// [Grade.Tenth]: "י",
	// [Grade.Eleventh]: 'י"א',
	// [Grade.Twelfth]: 'י"ב',
}

export const candidateEducations: Record<CandidateEducation, string> = {
    [CandidateEducation.HighSchool]: 'תיכונית',
    [CandidateEducation.AfterHighSchool]: 'על תיכונית',
    [CandidateEducation.TeachingCertificate]: 'תעודת הוראה',
    [CandidateEducation.FirstDegree]: 'תואר ראשון',
    [CandidateEducation.SecondDegree]: 'תואר שני',
    [CandidateEducation.ThirdDegree]: 'תואר שלישי',
}
