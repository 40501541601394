import makeStyles from "@material-ui/core/styles/makeStyles";

interface LogosProps {
	hideTeacherLogo?: boolean;
}

const Logos: React.FC<LogosProps> = ({ hideTeacherLogo = false }) => {

	const classes = useStyles();

	return (
		<div className={classes.logoContainer}>
			{!hideTeacherLogo && <img className={classes.logo} src='/images/teacher-logo.png' alt='' />}

			{/* <a href='https://www.hilma.tech/'>
				<img className={classes.logoHilma} src='/images/hilma-logo.png' alt='' />
			</a> */}
		</div>
	)
}

export default Logos;

const useStyles = makeStyles(theme => ({
	logo: {
		display: 'block',
		width: 60,
		[theme.breakpoints.down(600)]: {
			width: 40
		}
	},
	logoHilma: {
		width: 70,
		[theme.breakpoints.down(600)]: {
			width: 45
		}
	},
	logoContainer: {
		'&:hover': {
			cursor: 'pointer'
		},
		display: 'flex',
		gap: 20,
		[theme.breakpoints.down(600)]: {
			gap: 10
		}
	}
}));