import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import withStyles from '@material-ui/core/styles/withStyles';
import StepConnector from '@material-ui/core/StepConnector';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';

import { detailsSteps, lightTeal } from '../../constants';
import { useActiveStep, useSetActiveStep } from '../../hooks';

import StepIcon from './StepIcon';


const ColorlibConnector = withStyles({
	alternativeLabel: {
		top: 19,
	},
	active: {
		'& $line': {
			background: lightTeal
		},
	},
	completed: {
		'& $line': {
			background: lightTeal
		},
	},
	line: {
		height: 3,
		border: 0,
		backgroundColor: '#eaeaf0',
		borderRadius: 1,
	},
})(StepConnector);



const CustomizedStepper: React.FC = () => {

	const classes = useStyles();
	const activeStep = useActiveStep();

	return (
		<>
			<Stepper
				classes={{ root: classes.root }}
				alternativeLabel
				activeStep={activeStep}
				connector={<ColorlibConnector />}
			>
				{detailsSteps.map((step) => (
					<Step classes={{root: classes.stepRoot}} key={step.label}>
						<StepLabel
							StepIconComponent={StepIcon}
						/>
					</Step>
				))}
			</Stepper>
			<hr className={classes.hr} />
		</>
	);
}

export default CustomizedStepper;

const useStyles = makeStyles(theme => ({
	root: {
		background: 'transparent',
		padding: 0,
		margin: '16px 0',
		[theme.breakpoints.up(600)]: {
			width: '75vw',
			margin: '16px auto'
		}
	},
	stepRoot: {
		padding: 0
	},
	hr: {
		height: 1,
		position: 'absolute',
		left: 0,
		width: '100vw',
		background: '#00000029',
		border: 'none'
	}
}));
