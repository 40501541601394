import { Option, useForm } from '@hilma/forms';
import makeStyles from "@material-ui/core/styles/makeStyles";
import { ClassNameMap } from '@material-ui/styles';
import Checkbox from "@material-ui/core/Checkbox";
import clsx from "clsx";

import { backgroundGray, borderGray, boxShadowGray, teal } from "../constants";

export interface FormCheckboxProps {
	option: Option;
	listId: string;
	classes?:Partial<ClassNameMap<'container' | 'root'>>;
}

const FormCheckbox: React.FC<FormCheckboxProps> = ({ option, listId, classes: formClasses }) => {

	const classes = useStyles();
	const form = useForm();

	const onClick = () => {
		const checked = form.values[listId][option.id]
		form.setFieldValue(listId, { ...form.values[listId], [option.id]: !checked })
	}

	return (
		<div
			className={clsx(classes.container, formClasses?.container)}
			onClick={onClick}
		>
			<Checkbox
				className={clsx(classes.root, formClasses?.root)}
				disableRipple
				color="default"
				checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
				icon={<span className={classes.icon} />}
				inputProps={{ 'aria-label': 'decorative checkbox' }}
				id={String(option.id)}
				checked={form.values[listId]?.[option.id] || false}
			/>
			{option.value}
		</div>
	);
}

export default FormCheckbox;

const useStyles = makeStyles({
	container: {
		background: backgroundGray,
		color: teal,
		padding: '8px 0',
		'&:not(:last-child)': {
			borderBottom: `1px solid ${borderGray}`
		},
		boxShadow: `0px 3px 6px ${boxShadowGray}`,
		'&:hover': {
			cursor: 'pointer',
		},
	},
	root: {
		padding: '9px 15px',
		'&:hover': {
			backgroundColor: 'transparent',
		},
	},
	icon: {
		borderRadius: '50%',
		width: 16,
		height: 16,
		boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
		backgroundColor: '#f5f8fa',
		backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
		'$root.Mui-focusVisible &': {
			outline: '2px auto #3F788CAB',
			outlineOffset: 2,
		},
		'input:hover ~ &': {
			backgroundColor: '#ebf1f5',
		},
		'input:disabled ~ &': {
			boxShadow: 'none',
			background: 'rgba(206,217,224,.5)',
		},
	},
	checkedIcon: {
		backgroundColor: '#3F788C',
		backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
		'&:before': {
			display: 'block',
			width: 16,
			height: 16,
			backgroundImage:
				"url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
				" fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
				"1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
			content: '""',
		},
		'input:hover ~ &': {
			backgroundColor: '#3F788C',
		},
	},
});
