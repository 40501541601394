import Tooltip from "@material-ui/core/Tooltip";

interface Props {
	children: React.ReactElement;
	open: boolean;
	value: number;
}

function ValueLabel(props: Props) {
	const { children, open, value } = props;

	return (
		<Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
			{children}
		</Tooltip>
	);
}

export default ValueLabel;