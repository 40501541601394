import { useState } from "react";
import { FormProvider, useAlert, useForm, useGetError, useGoogleMaps } from "@hilma/forms";
import { provide, useAsyncEffect } from "@hilma/tools";
import { useParams } from "react-router-dom";
import axios from 'axios';

import { useSetDetailsLoading, useUpdateSaved, useUpdateVisited } from "../../hooks";
import { StepsProvider, SubjectsProvider } from "../../context";
import { personalDetailsRules } from "../../constants";

import PersonalDetailsForm from "./PersonalDetailsForm";
import BeginForm from "./BeginForm";

interface PersonalDetailsProps { //TODO: !
	// get name from local or db?
}


const initialValues = {
	address: '',
	radius: 30,
	city: '',
	grades: {},
	subjects: {},
	daysOfWeek: {},
	sectors: {},
	image: null,
	imageFile: null,
	imagePath: '',
	cv: null,
	cvPath: '',
	policeCheck: null,
	policeCheckPath: '',
	experience: null,
	gender:null,
	hasExperience:null,
	experiencePath: '',
	diploma: null,
	diplomaPath: '',
	recommendation: null,
	recommendationPath: '',
	requiredValues: [
		'address',
		'city',
		'radius'
	]
}

const PersonalDetails: React.FC<PersonalDetailsProps> = ({ }) => {

	const { hideInstructions } = useParams<{ hideInstructions?: string }>();

	const [beginForm, setBeginForm] = useState(!!hideInstructions);

	useGoogleMaps();
	const form = useForm();
	const alert = useAlert();
	const getError = useGetError();
	const updateSaved = useUpdateSaved();
	const updateVisited = useUpdateVisited();
	const setLoading = useSetDetailsLoading();

	useAsyncEffect(async () => {
		try {
			setLoading(true);

			const { data } = await axios.get('/api/candidate-pref/get-candidate-info');

			const { lastIndexChecked, ...rest } = data;

			await form.setValues((prev: any) => ({
				...prev,
				...rest,
			}))

			{
				[...Array(lastIndexChecked as number)].map((_, i) => {
					updateVisited(i);
				})
			}

		} catch (err: any) {
			console.error(err);
			alert(getError(err));
		} finally {
			setLoading(false);
		}
	}, []);

	return (
		<div>
			{beginForm ?
				<PersonalDetailsForm /> :
				<BeginForm setBeginForm={setBeginForm} />
			}
		</div>
	);
}

const PersonalDetailsComp: React.FC<PersonalDetailsProps> = (props) => (
	<StepsProvider>
		<FormProvider initialValues={initialValues} rules={personalDetailsRules}>
			<SubjectsProvider>
				<PersonalDetails {...props} />
			</SubjectsProvider>
		</FormProvider>
	</StepsProvider>
);
export default PersonalDetailsComp; 