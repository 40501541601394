export enum RequestCandidateStatus {
	Pending = 'pending',
	CandidateApproved = 'candidate-approved',
	CandidateDenied = 'candidate-denied',
	AdminApproved = 'admin-approved',
	AdminAssigned = 'admin-assigned',
	CandidateApprovedFinal = 'candidate-approved-final',
	CandidateDeniedFinal = 'candidate-denied-final',
	OtherAssignee = 'other-assignee',
	RequestDeleted = 'request-deleted',
	Unassigned = 'unassigned',
}