import { useMemo, useState } from "react";
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useAsyncEffect } from "@hilma/tools";
import { useAlert } from "@hilma/forms";
import axios from 'axios';

import { AppTitle, EditButton, LogoAndLogOut, Title, RequestListButton } from "../components";
import { useCandidateName, useCandidateStatus } from "../hooks";
import { homePageText } from "../constants";
import { UserStatus } from "../types";
import ContactUsButton from "../components/ContactUsButton";

const Welcome: React.FC = () => {

    const [numberOfRequests, setnumberOfRequests] = useState<number | null>(null);


    const candidateStatus = useCandidateStatus();
    const candidateName = useCandidateName();
    const classes = useStyles();
    const alert = useAlert();

    const text = useMemo(() => {
        //we'll change this switch case to a database check that will make sure the request exists and belongs to this phone number

        switch (candidateStatus) {
            case UserStatus.Approved:
            case UserStatus.CreatedByAdmin:
                return (
                    <>
                        {numberOfRequests ?
                            <div>
                                <p>שמחים על הצטרפותך למערכת מורה להחלפה</p>
                                <p>נמצאו <strong>{numberOfRequests}</strong> קריאות המתאימות לך</p>
                                <p>ניתן לצפות בהן בלחיצה על רשימת קריאות</p>
                            </div>
                            : homePageText.approved.map((t, index) => (
                                <p key={index}>{t}</p>
                            ))}
                    </>
                );
            case UserStatus.Pending:
                return (
                    <>
                        {homePageText.pending.map((t, index) => (
                            <p key={index}>{t}</p>
                        ))}
                    </>
                );
            case UserStatus.Denied:
                return (
                    <>
                        {homePageText.denied.map((t, index) => (
                            <p key={index}>{t}</p>
                        ))}
                    </>
                );
            default:
                return "";
        }
    }, [candidateStatus, numberOfRequests]);

    useAsyncEffect(async () => {
        try {
            const { data } = await axios.get('/api/request-candidate/get-candidate-requests-count');
            setnumberOfRequests(data);
        } catch (err: any) {
            console.error(err);
            alert('ארעה תקלה במערכת')
        }
    }, [])

    return (
        <div className={classes.container}>
            <LogoAndLogOut />
            <AppTitle />
            <div className={classes.info}>
                <Title>שלום {candidateName}!</Title>
                {text}
            </div>
            <div className={classes.buttons}>
                {candidateStatus === UserStatus.Approved && <RequestListButton />}
                <EditButton />
                <ContactUsButton />
            </div>
        </div>
    );
};

export default Welcome;

const useStyles = makeStyles(theme => ({
    container: {
        padding: "4vh 0vw 8vh",
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        boxSizing: "border-box",
    },
    info: {
        flex: 1,
        flexGrow: 1,
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        color: theme.palette.primary.main,
        fontSize: "1.1rem",
        textAlign: "center",
        gap: 18,
        marginBottom: "10vh",
        "&>p": {
            marginBlockStart: 0,
            marginBlockEnd: 0,
        },
    },
    buttons: {
        display: 'flex',
        flexDirection: 'column',
        gap: 20
    }
}));