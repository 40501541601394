import { useEffect, useState } from "react";
import {
  useForm,
  useOnSubmitRef,
  FormFile,
  FormImage,
  useAlert,
  useGetError,
} from "@hilma/forms";
import makeStyles from "@material-ui/core/styles/makeStyles";
import axios from "axios";

import {
  useCanShowCongratsFinished,
  useReject,
  useSetCanShowCongratsFinished,
  useSetDetailsLoading,
  useUpdateSaved,
} from "../../hooks";
import { FormInputLabel } from "../../general";
import { CongratsFinished, NextStep } from ".";
import { Container } from "..";
import { useIsAuthenticated } from "@hilma/auth";
import DownloadButton from "../Button/DownloadButton";

interface CandidateGenderRes {
  gender: string;
}

interface CandidateExperienceRes {
  hasExperience: string;
}

const UploadFiles: React.FC = () => {
  const form = useForm();
  const reject = useReject();
  const setLoading = useSetDetailsLoading();
  const updateSaved = useUpdateSaved();
  const canShowCongratsFinished = useCanShowCongratsFinished();
  const setCanShowCongratsFinished = useSetCanShowCongratsFinished();
  const onSubmitRef = useOnSubmitRef();
  const classes = useStyles();
  const [gender, setGender] = useState("");
  const [hasExperience, setHasExperience] = useState("");
  const alert = useAlert();
  const getError = useGetError();
  const isAuthenticated = useIsAuthenticated();

  const onSubmit = async () => {
    setLoading(true);

    onSubmitRef.current = save;
    const requiredValues = [
      "address",
      "radius",
      "daysOfWeek",
      "grade",
      "subjects",
      "cv",
    ];

    if (hasExperience === "לא") requiredValues.push("experience");
    if (gender === "זכר") requiredValues.push("policeCheck");
    await form.setFieldValue("requiredValues", requiredValues);
    console.log(form.values);
    if (!(form.values.cv || form.values.cvPath)) {
      setLoading(false);
      return Promise.reject();
    }
    if (
      !(form.values.experience || form.values.experiencePath) &&
      hasExperience === "לא"
    ) {
      setLoading(false);
      return Promise.reject();
    }
    if (!form.values.policeCheckPath && gender === "זכר") {
      setLoading(false);
      return Promise.reject();
    }
    form.handleSubmit();
  };

  const save = async () => {
    try {
      const toSend = {
        files: true,
        image: form.values.image,
        imageFile: form.values.imageFile,
        cv: form.values.cv,
        cvPath: form.values.cvPath,
        diploma: form.values.diploma,
        diplomaPath: form.values.diplomaPath,
        policeCheck: form.values.policeCheck,
        policeCheckPath: form.values.policeCheckPath,
        recommendation: form.values.recommendation,
        recommendationPath: form.values.recommendationPath,
        experience: form.values.experience,
        experiencePath: form.values.experiencePath,
      };
      if (form.values.imageFile instanceof File) delete toSend.image;

      await axios.post("/api/candidate-pref/save-candidate-files", toSend);
      setCanShowCongratsFinished(true);

      await updateSaved();
    } catch (err) {
      console.error("err: ", err);
      return reject(err);
    } finally {
      setLoading(false);
    }
  };

  const getCandidateGender = async () => {
    try {
      const { data } = await axios.get<CandidateGenderRes | null>(
        "/api/candidate/get-candidate-gender"
      );
      if (!data) return;
      setGender(data.gender);
    } catch (err: any) {
      console.error(err);
      alert(getError(err));
    }
  };

  const getCandidateExperience = async () => {
    try {
      const { data } = await axios.get<CandidateExperienceRes | null>(
        "/api/candidate/get-candidate-experience"
      );
      if (!data) return;
      setHasExperience(data.hasExperience);
    } catch (err: any) {
      console.error(err);
      alert(getError(err));
    }
  };

  useEffect(() => {
    if (!gender && isAuthenticated) getCandidateGender();
    if (isAuthenticated) getCandidateExperience();
    return () => {
      onSubmitRef.current = null;
    };
  }, []);

  if (canShowCongratsFinished) return <CongratsFinished />;

  return (
    <>
      <Container title="כמעט סיימנו" subTitle="טעינת הקבצים המבוקשים">
        <div className={classes.container}>
          <FormInputLabel id="image" description="תמונת פרופיל" />
          <FormImage id="image" size={2_000_000} />
          <FormFile
            id="cv"
            description="קורות חיים*"
            noAxiosOnFileDownload={process.env.NODE_ENV === "production"}
          />
          {gender === "זכר" ? (
            <div>
              {gender === "זכר" ? (
                <p
                  style={{
                    color: "#35788C",
                    fontSize: "1.2rem",
                    direction: "rtl",
                    textAlign: "right",
                  }}
                >
                  * כדי להשלים את תהליך הרישום ולהיכלל במאגר המורים המחליפים הנך
                  מתבקש לצרף אישור משטרה חתום - מצורף טופס "בקשת בגיר לקבל אישור
                  משטרה" יש לגשת עם הטופס לתחנת המשטרה,להנפיק אישור משטרה חתום
                  ולצרף את אישור המשטרה{" "}
                  <DownloadButton fileName={"police_approval.pdf"} />
                </p>
              ) : null}
              <FormFile
                id="policeCheck"
                description="תעודת יושר מהמשטרה*"
                noAxiosOnFileDownload={process.env.NODE_ENV === "production"}
              />
            </div>
          ) : null}

          {hasExperience === "לא" ? (
            <div>
              <p
                style={{
                  color: "#35788C",
                  fontSize: "1.2rem",
                  direction: "rtl",
                  textAlign: "right",
                }}
              >
                * כדי להשלים את תהליך הרישום ולהיכלל במאגר המורים המחליפים הנך
                מתבקש למלא טופס קליטה{" "}
                <DownloadButton fileName={"admission_form.pdf"} />
              </p>
              <FormFile
                id="experience"
                description="טופס קליטה*"
                noAxiosOnFileDownload={process.env.NODE_ENV === "production"}
              />
            </div>
          ) : null}

          <FormFile
            id="diploma"
            description="תעודות"
            noAxiosOnFileDownload={process.env.NODE_ENV === "production"}
          />
          <FormFile
            id="recommendation"
            description="המלצות"
            noAxiosOnFileDownload={process.env.NODE_ENV === "production"}
          />
        </div>
      </Container>

      <NextStep onNext={onSubmit} />
    </>
  );
};

export default UploadFiles;

const useStyles = makeStyles({
  container: {
    margin: "22px 0px 66px",
    textAlign: "center",
  },
});
