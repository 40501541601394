import { DaysOfWeek } from "../types";

export const daysOfWeekObject = {
  [DaysOfWeek.Sunday]: "ראשון",
  [DaysOfWeek.Monday]: "שני",
  [DaysOfWeek.Tuesday]: "שלישי",
  [DaysOfWeek.Wednesday]: "רביעי",
  [DaysOfWeek.Thursday]: "חמישי",
  [DaysOfWeek.Friday]: "שישי",
  [DaysOfWeek.Saturday]: "שבת",
};

export const daysOfWeekArray: { id: string; value: string }[] = Object.keys(
  daysOfWeekObject
).map((key) => ({
  id: key,
  value: daysOfWeekObject[Number(key) as DaysOfWeek],
}));

// : Record<DaysOfWeek, string>
