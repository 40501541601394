import React from "react";
import PropTypes from "prop-types";



interface YoutubeEmbedProps {
  embedId: string;
  startTime?: number;
}

const YoutubeEmbed: React.FC<YoutubeEmbedProps> = ({ embedId, startTime = 0}) => (
  <div className="video-responsive">
    <iframe
      width="853"
      height="400"
      src={`https://www.youtube.com/embed/${embedId}?start=${startTime}`}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded youtube"
    />
  </div>
);

YoutubeEmbed.propTypes = {
  embedId: PropTypes.string.isRequired,
};

export default YoutubeEmbed;
