import { StepIconProps } from "@material-ui/core";
import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';

import { useCanShowCongratsFinished, useSetActiveStep, useSteps } from "../../hooks";
import { backgroundGray, lightTeal, teal, white } from "../../constants";

import IconSvg from "./IconSvg";

const StepIcon: React.FC<StepIconProps> = ({ active, completed, icon }) => {

	const classes = useColorlibStepIconStyles();
	const steps = useSteps();
	const setActiveStep = useSetActiveStep();

	const checked = steps[(icon as number) - 1].saved;
	const showCongratsFinished = useCanShowCongratsFinished()

	return (
		<div
			className={clsx(classes.root,
				(showCongratsFinished || !checked || !icon) && classes.noClick, {
				[classes.completed]: checked,
				[classes.active]: icon === 5 ? (!completed && !showCongratsFinished && active) : active,
			})}

			onClick={() => {
				if (!showCongratsFinished && checked && icon) {
					setActiveStep(Number(icon) - 1)
				}
			}}
		>
			<IconSvg
				active={active}
				completed={checked}
				icon={icon}
			/>
		</div>
	);
}

export default StepIcon;


const useColorlibStepIconStyles = makeStyles(theme => ({
	root: {
		background: backgroundGray,
		zIndex: 1,
		color: white,
		width: 38,
		height: 38,
		display: 'flex',
		borderRadius: '50%',
		justifyContent: 'center',
		alignItems: 'center',
		boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
		transition: 'background 0.1s linear',
		padding: 0
		// [theme.breakpoints.down('sm')]: {
		// 	width: 30,
		// 	height: 30,
		// }
	},
	noClick: {
		cursor: 'auto !important',
	},
	active: {
		background: `${teal} !important`,
		boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
		border: '1px solid white',
		boxSizing: 'border-box',
		transition: 'background 0.1s linear'
	},
	completed: {
		background: lightTeal,
		color: 'red',
		transition: 'background 0.1s linear',
		'&:hover': {
			cursor: 'pointer',
		},
	},
}));

