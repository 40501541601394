import { Dialog } from '@material-ui/core';
import { ReactElement, useState } from 'react';
import MuiButton from '@material-ui/core/Button';

import { usePopupStyles } from './popupStyles';
import Button from '../Button/Button';

interface ConfirmPopupProps {
    open: boolean;
    title: string | ReactElement | undefined;
    text: string | ReactElement | undefined;
    cancelText?: string;
    confirmText?: string;
    onConfirm?: () => void | Promise<void>;
    onClose?: () => void;
}

export const ConfirmPopup: React.FC<ConfirmPopupProps> = ({ open, title, text, cancelText, confirmText, onConfirm, onClose }) => {

    const [buttonLoading, setButtonLoading] = useState<boolean>(false)
    const classes = usePopupStyles()

    const handleConfirm = async () => {
        if (!onConfirm) return
        setButtonLoading(true)
        await onConfirm()
        setButtonLoading(false)
    }

    return (
        <Dialog dir="rtl" onClose={() => { }} open={open}>
            <div className={classes.container}>
                <div className={classes.title} >{title}</div>
                <div className={classes.text} >{text}</div>
                <div className={classes.bottom} >
                    <MuiButton variant="outlined" disabled={buttonLoading} className={classes.cancel} onClick={onClose && onClose}>
                        {cancelText || "ביטול"}
                    </MuiButton>
                    <Button loading={buttonLoading} className={classes.button} onClick={handleConfirm} >
                        {confirmText || "אישור"}
                    </Button>
                </div>
            </div>
        </Dialog>
    )
}
export default ConfirmPopup
