import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import MenuIcon from "@material-ui/icons/Menu";

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
});

type Anchor = "top" | "left" | "bottom" | "right";

export default function DropDown() {
  const classes = useStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const pageLinks = [
    { id: 1, href: "#howItWork", text: "איך זה עובד?" },
    {
      id: 2,
      href: "https://meyda.education.gov.il/files/Hashavut/tfasim/2-%20Shelonlmm.pdf",
      text: "פורטל עובדי הוראה",
    },
    { id: 3, href: "../../files/conditions.pdf", text: "תנאי העסקה" },
    { id: 4, href: "#contact", text: "צור קשר" },
  ];

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };

  const list = (anchor: Anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {pageLinks.map((link) => (
          <ListItem button key={link.id}>
            <a
              href={link.href}
              className="nav-link"
              style={{
                color: "black",
                textDecoration: "none",
                fontSize: "16px",
              }}
              download={link.id === 3 ? "/files/conditions.pdf" : undefined}
            >
              {link.text}
            </a>
          </ListItem>
        ))}
      </List>
    </div>
  );

  return (
    <div className="menu-button">
      {(["left"] as Anchor[]).map((anchor) => (
        <React.Fragment key={anchor}>
          <Button onClick={toggleDrawer(anchor, true)}>
            <MenuIcon fontSize="large" style={{ color: "white" }} />
          </Button>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
