import makeStyles from "@material-ui/core/styles/makeStyles";
import { ClassNameMap } from "@material-ui/styles";
import { Option } from "@hilma/forms";
import clsx from "clsx";

import FormCheckbox, { FormCheckboxProps } from "./FormCheckbox";

interface FormCheckboxListProps {
  options?: Option[];
  id: string;
  classes?: FormCheckboxProps["classes"] &
    Partial<ClassNameMap<"outerContainer">>;
}

const FormCheckboxList: React.FC<FormCheckboxListProps> = ({
  options = [],
  id,
  classes: formClasses,
}) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.container, formClasses?.outerContainer)}>
      {options.map((option) => (
        <FormCheckbox
          option={option}
          key={option.id}
          listId={id}
          classes={formClasses}
        />
      ))}
    </div>
  );
};

export default FormCheckboxList;

const useStyles = makeStyles({
  container: {
    margin: "20px 0 66px",
  },
});
