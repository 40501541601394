import { useEffect } from 'react';
import { useForm } from '@hilma/forms';
import axios from 'axios';

import { useGetOptions, useReject, useSetDisableNext, useSomeSelected, useUpdateSubmit, useUpdateSaved } from '../../hooks';
import { grades as gradesObj } from '../../constants';
import { Container, FormCheckboxList } from '..';
import { Grade as Grad } from "../../types";


const Sector: React.FC = () => {

	const options = useGetOptions(Grad, gradesObj, 'grades');
	const form = useForm();
	const setDisableNext = useSetDisableNext();
	const someSelected = useSomeSelected(form.values.grades);
	const reject = useReject();
	const updateSaved = useUpdateSaved();

	const onSubmit = async () => {
		await form.setFieldValue('requiredValues', ['address', 'radius', 'daysOfWeek', 'sectors', 'grades']);

		form.handleSubmit();

		try {

			await axios.post('/api/candidate-pref/save-candidate-grades', { grades: form.values.grades });

			await updateSaved();

		} catch (err: any) {
			return reject(err);
		}
	}

	useUpdateSubmit(onSubmit);


	useEffect(() => {
		if (someSelected) return setDisableNext(false);
		return setDisableNext(true);
	}, [someSelected]);

	return (
		<Container
			title='כיתות'
			subTitle='באילו כיתות מתאים לך ללמד?'
		>
			<FormCheckboxList id='grades' options={options} />
		</Container>
	);
}

export default Sector;