import { useNavigate } from "react-router";
import NotificationsIcon from '@material-ui/icons/Notifications';
import makeStyles from "@material-ui/core/styles/makeStyles";

import { Button } from ".";

const RequestListButton: React.FC = () => {

	const classes = useStyles();
	const navigate = useNavigate();

	return (
		<Button
			className={classes.button}
			onClick={() => navigate('/list-of-requests')}
			variant='contained'
			children={<><NotificationsIcon className={classes.editIcon} />רשימת קריאות</>}
		/>
	);
}

export default RequestListButton;

const useStyles = makeStyles({
	editIcon: {
		marginRight: 10,
	},
	button: {
		width: 'fit-content',
		margin: '0 auto',
	}
})