import Logos from './Logos';

const style = {
    AccessibilityWrapper: { marginTop: "9vh", padding: "0 2vw" },
    AccessibilityContent: { padding: "2vw 2vw", height: "100vh" }
}

const Accessibility = () => {

    return (
        <div style={style.AccessibilityContent}>
            <Logos />

            <div style={style.AccessibilityWrapper}>
                <span style={{ textAlign: "start" }}>
                    <h2>הצהרת נגישות</h2>
                    <p>  התאמות הנגישות של החברה בוצעו עפ״י המלצות התקן הישראלי (ת״י 5568) לנגישות תכנים באינטרנט ברמת AA וכן עפ״י מסמך 2.0 WCAG הבינלאומי.<br />
                        במקומות בהם התקן לא התאים בוצעו התאמות נגישות בהתאם ליכולות הנגישות של מערכת ההפעלה.</p>

                    <p>הבדיקות נבחנו לתאימות הגבוהה ביותר עבור דפדפנים Chrome ו- Firefox.</p>

                    <p>האתר מספק מבנה סמנטי עבור טכנולוגיות מסייעות ותמיכה בדפוס השימוש המקובל להפעלה עם מקלדת בעזרת מקשי החיצים, Enter ו- Esc ליציאה מתפריטים וחלונות.</p>

                    <p>מורה להחלפה ממשיכים כל העת לשפר את השרות הניתן לאנשים עם מוגבלות. אם נתקלתם בשירות לא נגיש או בכל שאלה ו/או הצעה בנושא, אנא פנו אלינו במייל moralhahlafa@gmail.com </p>

                </span>

            </div >
        </div>
    )
}

export default Accessibility;