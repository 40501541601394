import makeStyles from "@material-ui/core/styles/makeStyles";
import { useHasTwoFactorToken } from "@hilma/auth";
import clsx from "clsx";

import { AppTitle, Logos, SignInCode, Title } from "../components";
import { useReusableStyles } from "../hooks";
import { SignUp } from ".";


const SignUpOrIn: React.FC = () => {

	const classes = useStyles();
	const hasTwoFactorToken = useHasTwoFactorToken();

	const rClasses = useReusableStyles();



	return (
		<div className={clsx(rClasses.container, classes.container)}>
			<Logos />
			<AppTitle />

			<Title>הרשמה למערכת</Title>

			<div className={classes.formContainer}>
				{!hasTwoFactorToken ?
					<SignUp /> :
					<SignInCode />
				}
			</div>
		</div>
	);
}

export default SignUpOrIn;

const useStyles = makeStyles(theme => ({
	container: {
		paddingBottom: 20,
		height: 'auto'
	},
	formContainer: {
		margin: 'auto',
		display: 'flex',
		flexDirection: 'column',
		[theme.breakpoints.up(600)]: {
			width: '52vw'
		}
	}
}))
