import { useCallback, useEffect, useState } from "react";
import { FormInput, FormProvider, useAlert, useForm, useUpdateOnSubmit } from "@hilma/forms";
import { useHasTwoFactorToken, useLoginWithCode, useRemoveAuthItem } from "@hilma/auth";
import makeStyles from "@material-ui/core/styles/makeStyles";
import MuiButton from "@material-ui/core/Button";
import { useLocation, useNavigate } from "react-router";
import { provide } from "@hilma/tools";
import axios from "axios";
import clsx from "clsx";

import { useStyles as useSignInStyles } from "../routes/SignIn";
import { signInErrorGenerator } from "../functions";
import { loginCodeRules } from "../constants";
import { Button } from ".";
import { Storage } from "../types";

interface SignInCodeProps { }

const initialValues = { code: "" };

const SignInCode: React.FC<SignInCodeProps> = ({ }) => {
  const [loading, setLoading] = useState(false);

  const hasTwoFactorToken = useHasTwoFactorToken();

  const loginWithCode = useLoginWithCode();

  const form = useForm();

  const alert = useAlert();
  const navigate = useNavigate();
  const removeAuthItem = useRemoveAuthItem();

  const signInClasses = useSignInStyles();
  const classes = useStyles();

  const locationString = useLocation();

  const removeToken = useCallback(() => {
    return removeAuthItem<Storage>("lkoo");
  }, [removeAuthItem]);

  useUpdateOnSubmit(async (values) => {
    try {
      setLoading(true);

      let code = "000000" + values.code;
      const { success, msg } = await loginWithCode(
        "/api/candidate/login-with-code",
        code.substring(code.length - 6)
      );
      if (!success) setLoading(false);
      if (!success && !msg?.data.key) alert("אירעה שגיאה");
      if (!success && msg?.data.key) alert(signInErrorGenerator(msg.data.key));
      sessionStorage.removeItem('loggedIn');
      const { data } = await axios.get<boolean>(
        "/api/candidate/is-user-in-progress"
      );

      if (locationString?.pathname !== "/listOfRequests") {
        navigate(data ? "/personal-details" : "/");
      }
      //   else {
      //     console.log("list of requests");

      //     history.push("/listOfRequests");
      //   }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  });

  function handleEnter(event: any) {
    if (event.key === 'Enter') {
      form.handleSubmit()
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', handleEnter)
    return () => {
      document.removeEventListener('keydown', handleEnter)
    };

  }, [])

  return (
    <>
      <div className={signInClasses.title}>
        נשלח אליך ברגעים אלו קוד
        <br /> אימות לטלפון. הכנס אותו כאן
      </div>

      <FormInput
        id="code"
        type='tel'
        description="קוד אימות"
        autoComplete="off"
        autoFocus
      />

      <Button
        onClick={form.handleSubmit as () => void}
        type="submit"
        loading={loading}
      >
        כניסה
      </Button>

      <div className={classes.linkContainer}>
        {hasTwoFactorToken && (
          <MuiButton
            variant="text"
            color="primary"
            onClick={removeToken}
            disableRipple
            className={clsx(signInClasses.title, classes.link)}
            children="חזור"
          />
        )}
      </div>
    </>
  );
};

const SignInCodeComp: React.FC<SignInCodeProps> = (props) => (
  <FormProvider initialValues={initialValues} rules={loginCodeRules}>
    <SignInCode {...props} />
  </FormProvider>
);
export default SignInCodeComp;

const useStyles = makeStyles((theme) => ({
  link: {
    fontSize: "1.2rem",
    fontFamily: "Assistant-Regular",
    width: "fit-content",

    textDecoration: "underline",
    "&:hover": {
      background: "none",
      textDecoration: "underline",
    },
  },
  linkContainer: {
    marginLeft: "auto",
    marginRight: "auto",
  },
}));
