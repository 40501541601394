import makeStyles from "@material-ui/core/styles/makeStyles";

import { useReusableStyles, useCurrentComponent, useActiveStep } from '../../hooks';
import { CustomStepper, LogoAndLogOut, NextStep } from "../../components";
import { NUMBER_OF_STEPS } from "../../constants";

const PersonalDetailsForm: React.FC = () => {

	const classes = useStyles();
	const rClasses = useReusableStyles();
	const CurrentComponent = useCurrentComponent();
	const activeStep = useActiveStep();

	return (
		<div className={rClasses.background}>

			<div className={classes.content}>

				<div>
					<LogoAndLogOut />
					<CustomStepper />
				</div>

				<CurrentComponent />

				{activeStep !== NUMBER_OF_STEPS - 1 && <NextStep />}

			</div>
		</div>
	);
}

export default PersonalDetailsForm;

const useStyles = makeStyles(theme => ({
	content: {
		paddingTop: '4vh',
		position: 'absolute',
		width: '100vw'
	},
}));