import { useContext } from 'react';
import { GetSubjectsContext, SubjectsContext } from '../context';

export const useSubjects = () => useContext(SubjectsContext)!;

export const useGetSubjects = () => {
	const getSubjects = useContext(GetSubjectsContext)!
	return async () => {
		await getSubjects();
	}
}