import makeStyles from "@material-ui/core/styles/makeStyles";

const useReusableStyles = makeStyles(theme => ({
	container: {
		margin: 'auto',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		minHeight: '100vh',
		boxSizing: 'border-box',
		padding: '4vh 11vw 8vh',
		backgroundSize: '60%',
		[theme.breakpoints.down(600)]: {
			backgroundSize: '200%',
		}
	},
	background: {
		minHeight: '100vh',
		boxSizing: 'border-box',
	},
	reset: {
		all: 'initial',
		display: 'block',
		textAlign: 'center',
		color: theme.palette.primary.main,
		fontFamily: 'Assistant-Regular'
	},
	button: {
		[theme.breakpoints.up(600)]: {
			width: '20vw !important',
			margin: 'auto',
		},
	},
	formContainer: {
		margin: 'auto',
		display: 'flex',
		flexDirection: 'column',
		[theme.breakpoints.up(600)]: {
			width: '52vw'
		}
	}
}));

export default useReusableStyles;