import React, { createContext, useEffect, useState } from 'react';
import { useAlert, useGetError } from '@hilma/forms';
import { useIsAuthenticated } from '@hilma/auth';
import { wrap } from '@hilma/tools';
import axios from 'axios';

import { UserStatus } from '../types';


export const CandidateStatusContext = createContext<UserStatus | null>(null);
// export const CandidateNumberOfRequestsContext = createContext<number>(0);
export const CandidateNameContext = createContext<string | null>(null);

interface CandidateStatusAndNameRes {
	// numberOfRequests: number;
	status: UserStatus;
	name: string;
}

const CandidateStatusAndNameProvider: React.FC = ({ children }) => {

	const [candidateStatus, setCandidateStatus] = useState<UserStatus | null>(null);
	const [candidateName, setCandidateName] = useState<string | null>(null);
	// const [numberOfRequests, setNumberOfRequest] = useState<number>(0);

	const alert = useAlert();
	const getError = useGetError();
	const isAuthenticated = useIsAuthenticated();


	const getCandidateStatusAndName = async () => {
		try {
			const { data } = await axios.get<CandidateStatusAndNameRes | null>('/api/candidate/get-candidate-status-and-name');
			if (!data) return;
			setCandidateName(data.name);
			setCandidateStatus(data.status);
			// setNumberOfRequest(data.numberOfRequests);
		} catch (err: any) {
			console.error(err);
			alert(getError(err));
		}
	}

	useEffect(() => {
		if (!candidateName && !candidateStatus && isAuthenticated)
			getCandidateStatusAndName();
	}, [isAuthenticated])

	return (
		// [CandidateNumberOfRequestsContext.Provider, { value: numberOfRequests }],
		<CandidateStatusContext.Provider value={candidateStatus}>
			<CandidateNameContext.Provider value={candidateName}>
				{children}
			</CandidateNameContext.Provider>
		</CandidateStatusContext.Provider>
	);
};

export default CandidateStatusAndNameProvider;