import { Option } from "@hilma/forms";
import { CandidateJob } from "../types";

export const CandidateJobOptions: Option[] = [
	{
		id: CandidateJob.Teacher,
		value: 'גננת'
	},
	{
		id: CandidateJob.Assistant,
		value: 'סייעת'
	},
	{
		id: CandidateJob.Approved,
		value: 'מאושר'
	},
	{
		id: CandidateJob.Denied,
		value: 'נדחה'
	},
	{
		id: CandidateJob.Pending,
		value: 'בהמתנה'
	}
];