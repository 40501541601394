import React from 'react';
import { InputClassKey, InputProps } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { ClassNameMap } from '@material-ui/styles';
import Slider from '@material-ui/core/Slider';
import { /* FormInputLabel, */ useForm, useTextFieldError, FormHelperText } from '@hilma/forms';
import clsx from 'clsx';

import ValueLabel from './ValueLabel';


interface FormSliderProps extends InputProps {
	id: string;
	description: string;
	loading?: boolean;
	minimum?: number;
	helperText?: string;
	labelAdornment?: React.ReactNode;
	classes?: Partial<ClassNameMap<InputClassKey | 'container' | 'labelContainer' | 'label' | 'maxLength' | 'helperText' | 'errorContainer'>>;
}

const FormSlider: React.FC<FormSliderProps> = ({ id, dir, description, loading, helperText, autoComplete: ac, labelAdornment, classes: { container, labelContainer, label, maxLength: maxLengthClass, helperText: helperTextClassName, errorContainer } = {}, minimum }) => {

	const form = useForm();
	const classes = useStyles();

	const fieldError = useTextFieldError(id);

	const handleChange = (_: any, newValue: number | number[]) => {
		form.setFieldValue(id, newValue);
	};

	return (
		<div>
			{/* TODO: */}
			{/* <FormInputLabel id={id}
				labelAdornment={labelAdornment}
				description={description}
				classes={{ labelContainer, label, maxLength: maxLengthClass }}
			/> */}

			<Slider
				dir={dir || 'rtl'}
				classes={{ root: classes.root, rail: classes.track, track: classes.track, thumb: classes.kjl, }}
				disabled={loading}
				value={form.values[id]}
				onChange={handleChange}
				aria-labelledby="continuous-slider"
				ValueLabelComponent={ValueLabel}
				min={minimum}
			/>

			{helperText && <p className={clsx(classes.helperText, helperTextClassName)}>{helperText}</p>}
			<FormHelperText className={errorContainer} text={fieldError} error />

		</div>
	);
}

export default FormSlider;

const useStyles = makeStyles(theme => ({
	root: {
		marginBottom: 0,
		paddingBottom: 10
	},
	track: {
		height: 5,
		borderRadius: 5
	},
	kjl: {
		height: 14,
		width: 14
	},
	helperText: {
		margin: 0,
		color: theme.palette.primary.main,
		[theme.breakpoints.up(500)]: {
			height: 15,
			fontSize: 11,
		},
		[theme.breakpoints.down(500)]: {
			height: 18,
			fontSize: 14,
		},
		[theme.breakpoints.up(1600)]: {
			height: 20,
			fontSize: 16,
		},
	}
}));
