import { useMemo } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { RequestCandidateStatus } from '../../types';
import { PopupButton } from "../../components";

interface RequestActionByStatusProps {
    onClick(newStatus: RequestCandidateStatus): Promise<void>;
    changeStatus(status: RequestCandidateStatus): void;
    status: RequestCandidateStatus;
    datePassed: boolean;
    loading: boolean;
};

const RequestActionByStatus: React.FC<RequestActionByStatusProps> = ({ status, datePassed, onClick, loading, changeStatus }) => {

    const isMobile = useMediaQuery('(max-width: 600px)');
    const classes = useStyles();

    const component = useMemo(() => (() => {
        switch (status) {
            case RequestCandidateStatus.Pending:
                return (
                    <>
                        <PopupButton disabled={datePassed} loading={loading} children='מעוניין/ת' onClick={() => onClick(RequestCandidateStatus.CandidateApproved)} />
                        <PopupButton isSecondary disabled={datePassed} loading={loading} children='לא מעוניין/ת' onClick={() => onClick(RequestCandidateStatus.CandidateDenied)} />
                    </>
                )
            case RequestCandidateStatus.CandidateApproved:
                return (
                    <>
                        <div className={classes.text}>מחכה לאישור מנהל\ת בית הספר</div>
                        {!isMobile && <PopupButton disabled children='מעוניין/ת' />}
                    </>
                )
            case RequestCandidateStatus.CandidateDenied:
                return (
                    <>
                        {!datePassed && <PopupButton loading={loading} children='שינוי בחירה' onClick={() => changeStatus(RequestCandidateStatus.Pending)} />}
                        <PopupButton disabled children='לא מעוניין/ת' />
                    </>
                )
            case RequestCandidateStatus.CandidateDeniedFinal:
                return (
                    <PopupButton isSecondary disabled children='סירוב לצמיתות' />
                )
            case RequestCandidateStatus.AdminApproved:
                return (
                    <>
                        <PopupButton disabled={datePassed} children='אישור סופי' loading={loading} onClick={() => onClick(RequestCandidateStatus.CandidateApprovedFinal)} popupText='האם את/ה בטוח/ה שברצונך להשתבץ לקריאה זו?' />
                        <PopupButton isSecondary disabled={datePassed} children='סירוב לצמיתות' loading={loading} onClick={() => onClick(RequestCandidateStatus.CandidateDeniedFinal)} popupText={<div>האם את/ה בטוח/ה שברצונך <strong>לסרב</strong> להשתבץ לקריאה זו?</div>} />
                    </>
                )
            case RequestCandidateStatus.AdminAssigned:
            case RequestCandidateStatus.CandidateApprovedFinal:
                return (
                    <div className={classes.text}>משובץ</div>
                )
            case RequestCandidateStatus.OtherAssignee:
                return (
                    <div className={classes.text}>הבקשה שובצה למורה אחר\ת</div>
                )
            case RequestCandidateStatus.RequestDeleted:
                return (
                    <div className={classes.text}>הבקשה נמחקה</div>
                )
            case RequestCandidateStatus.Unassigned:
                return (
                    <div className={classes.text}>השיבוץ בוטל</div>
                )
            default: <></>;
        }
    })() || null, [status, loading, datePassed, onClick, changeStatus]);

    return component;
};

export default RequestActionByStatus;

const useStyles = makeStyles(theme => ({
    text: {
        color: theme.palette.primary.main,
        fontFamily: 'Assistant-Bold',
    },
}));