import { useState } from 'react';
import { useAsyncEffect } from '@hilma/tools'
import { useAlert, FormAutocomplete, FormInput, useForm } from '@hilma/forms';

//mui
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import parse from 'autosuggest-highlight/parse';
import { AutocompletePrediction, AutocompleteService } from '../types';

const autocompleteService: { current: AutocompleteService | null } = { current: null };

interface LocationAutocompleteProps {
	id: string;
	description: string;
	lat?: number;
	lng?: number;
	noOptionsText?: string;
	address?: string;
	loading?: boolean;
}

const LocationAutocomplete: React.FC<LocationAutocompleteProps> = ({ id, description, address, lat, lng, noOptionsText, loading }) => {
	const [initialLocation, setInitialLocation] = useState<AutocompletePrediction | undefined>();

	const classes = useStyles();
	const alert = useAlert();
	const form = useForm();

	useAsyncEffect(async () => {
		if (loading || !lat || !lng || !description || !address) return;
		try {
			const predictions = await fetchPredictions(address, new google.maps.LatLng(lat, lng));
			setInitialLocation(predictions[0]);
		} catch (err) {
			console.error('err: ', err);
			alert('שגיאה בהבאת מידע')
		}
	}, [lat, lng, loading, description]);

	const fetchPredictions = async (input: string, location?: google.maps.LatLng) => {
		if (!input) return [];
		if (!autocompleteService.current && window.google) {
			autocompleteService.current = new window.google.maps.places.AutocompleteService();
		}
		const predictions = await new Promise<AutocompletePrediction[]>(reslove => autocompleteService.current!.getPlacePredictions({ input, ...(location ? { location, radius: 1 } : {}) }, reslove));
		return predictions || [];
	}

	if (loading || (lat && lng && !initialLocation)) return <FormInput id={id} description={description} loading={true} />

	return (
		<FormAutocomplete
			id={id}
			description={description}
			noOptionsText={noOptionsText || "הכנס כתובת"}
			initialValue={initialLocation}
			fetchFunction={fetchPredictions}
			getOptionSelected={(option: AutocompletePrediction, value: AutocompletePrediction) => value.place_id === option.place_id}
			defaultValue={typeof form.values[id] === 'string' ? form.values[id] : form.values[id].description}
			getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
			renderOption={(option) => {
				const matches = option?.structured_formatting?.main_text_matched_substrings || [];
				const parts = parse(
					option.structured_formatting.main_text,
					matches.map(match => [match.offset, match.offset + match.length]),
				);
				return (
					<Grid container alignItems="center" >
						<Grid item>
							<LocationOnIcon className={classes.icon} />
						</Grid>
						<Grid item xs>
							{parts.map((part: { text: string; highlight: boolean }, index: number) => (
								<span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
									{part.text}
								</span>
							))}
							<Typography variant="body2" color="textSecondary">
								{option.structured_formatting.secondary_text}
							</Typography>
						</Grid>
					</Grid>
				);
			}}
		/>
	);
}

export default LocationAutocomplete

const useStyles = makeStyles(theme => ({
	icon: {
		color: theme.palette.text.secondary,
		marginRight: theme.spacing(2),
	},

}));
