import { errors } from "../constants";

export const generateError = (err: any) => {

	const errText = errors;
	
	switch (err?.status) {
		case 401:
			return errText.plsLogin;
		case 404:
			if (err.data.message === "Please specify location") return errText.specifyLocation;
			else if (err.data.message === "Place not found") return errText.googleMapsStation.locationNotFound;
			else return errText.generalSystem;
		case 422:
			return errText.googleMapsStation.israelLoc;
		default: return errText.generalSystem;
	}
};