import { activeEmailRegex, activePhoneRegex, createRules, fullNameRegex } from "@hilma/forms";
import { isEmail, isMobilePhone, length } from 'class-validator';

export const signUpRules = createRules({
	name: { required: true, name: 'השם', greaterThan: 2, smallerThan: 45, regex: fullNameRegex },
	phone: { regex: activePhoneRegex, required: true, classValidator: (value: string) => isMobilePhone(value, 'he-IL') && (new RegExp(/^\d+/)).test(value) && length(value, 10, 10) },
	email: { required: true, regex: activeEmailRegex, classValidator: (value: string) => isEmail(value) },
	gender: {required: true},
	hasExperience: { required: true},
	education: { required: true, activeRegexErr: 'הינו חובה', classValidator: (education) => education && Object.values(education).some(Boolean) },
	terms: { required: true },
});
