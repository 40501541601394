import { Option, useForm, useFormInputStyles, FormHelperText, useTextFieldError } from '@hilma/forms';
import makeStyles from "@material-ui/core/styles/makeStyles";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from '@material-ui/core/RadioGroup';
import clsx from "clsx";

import RadioButton from './RadioButton';

interface RadioGroupInputProps {
	id: string;
	description: string;
	options: Option[];
}

const RadioGroupInput: React.FC<RadioGroupInputProps> = ({ id, description, options }) => {


	const form = useForm();
	const classes = useStyles();

	const formInputClasses = useFormInputStyles();

	const fieldError = useTextFieldError(id);


	const handleChange = (_: React.ChangeEvent<HTMLInputElement>, value: string) => {
		form.setFieldValue(id, value);
	};

	return (
		<FormControl component="fieldset" className={classes.container}>

			<div className={clsx(formInputClasses.containLables)}>
				<FormLabel htmlFor={id} className={formInputClasses.label}>{description}</FormLabel>
			</div>


			<RadioGroup aria-label={description} name={description} value={form.values[id]} onChange={handleChange}>
				{options.map(option =>
					<FormControlLabel
						checked={option.id === form.values[id]}
						key={option.id}
						value={option.id}
						control={<RadioButton />}
						label={option.value}
					/>
				)}
			</RadioGroup>

			<FormHelperText text={fieldError} error />

		</FormControl>
	);
}

export default RadioGroupInput;

const useStyles = makeStyles(theme => ({
	container: {
		color: theme.palette.primary.main
	},
}));