import { isEmail } from 'class-validator';
import { activePhoneRegex, activeEmailRegex, fullNameRegex, phoneRegex } from '@hilma/forms';
import { createRules } from '@hilma/forms';

// import { createRules } from '../../functions';

export const contactUsRules = createRules({
    name: { name: i18n => i18n.cntctRl.fullName, regex: fullNameRegex, greaterThan: 2, smallerThan: 45, required: true },
    phone: { name: i18n => i18n.assoRl.phone, regex: phoneRegex, activeRegex: activePhoneRegex, activeRegexErr: i18n => i18n.errText.numbersOnly, required: true, length: 10 },
    title: { required: false, smallerThan: 150 },
    email: { activeRegex: activeEmailRegex, required: true, classValidator: (value: string) => isEmail(value) },
    content: { name: i18n => i18n.cntctRl.msg, smallerThan: 1000, required: true },
});