import { Enum } from "../types";


export function createObjectsForCheckboxes<E extends Enum<E>>(enm: E, obj: { [key in E as string]: string | number }) {

	const checked = Object.entries(enm).reduce((prev, [key, _]) => ({ ...prev, [key]: false }), {});

	const options = Object.entries(enm).map(([key, value]) => ({ id: key, value: obj[value] })).filter(v => !!v.value);

	return { checked, options }
}