import { FormHelperText, useFormInputStyles, useTextFieldError } from '@hilma/forms';
import makeStyles from '@material-ui/core/styles/makeStyles';
import FormLabel from '@material-ui/core/FormLabel';

import { candidateEducations } from '../constants';
import { CandidateEducation } from '../types';
import { useGetOptions } from '../hooks';

import FormCheckboxList from './FormCheckboxList';

interface EducationMultipleSelectProps { };

const EducationMultipleSelect: React.FC<EducationMultipleSelectProps> = ({ }) => {

    const options = useGetOptions(CandidateEducation, candidateEducations, 'education');
    const educationFieldError = useTextFieldError('education');
    const formInputClasses = useFormInputStyles();
    const classes = useStyles();


    return (
        <div>
            <FormLabel htmlFor='education' className={formInputClasses.label}>השכלה</FormLabel>
            <FormCheckboxList id='education' options={options} classes={{ outerContainer: classes.educationOuterContainer, container: classes.educationContainer, root: classes.educationRoot }} />
            <FormHelperText text={educationFieldError} error />
        </div>
    )
};

export default EducationMultipleSelect;

const useStyles = makeStyles(({
    educationContainer: {
        background: 'transparent !important',
        boxShadow: 'none !important',
        borderColor: 'transparent !important',
        padding: '4px 0 !important'
    },
    educationOuterContainer: {
        margin: '10px 0 !important'
    },
    educationRoot: {
        padding: '4px 20px 4px 0px !important'
    },
}));