import { useState } from "react";
import { Option, useForm } from "@hilma/forms";
import { useAsyncEffect } from "@hilma/tools";

import { createObjectsForCheckboxes } from "../functions";
import { Enum } from "../types";

function useGetOptions<E extends Enum<E>>(enm: E, obj: { [key in E as string]: string | number }, fieldId: string) {

	const [options, setOptions] = useState<Option[]>([]);

	const form = useForm();

	useAsyncEffect(async () => {

		const { checked, options } = createObjectsForCheckboxes(enm, obj);

		setOptions(options);

		if (!!form.values[fieldId] || !!Object.entries(form.values[fieldId]).length) return;

		await form.setFieldValue(fieldId, checked);
	}, []);

	return options;
}

export default useGetOptions;