import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";

const Footer: React.FC = () => {
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [position, setPosition] = useState("");
  const [message, setMessage] = useState("");

  const handleSendDetails = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!fullName || !email || !phone) {
      toast.error('שמ, מספר טלפון וכתובת דוא"ל הם שדות חובה', {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return
    }
    const content = `שם ושם משפחה: ${fullName}\nאימייל: ${email}\nטלפון: ${phone}\nתפקיד ושם הארגון: ${position}\nהודעה: ${message}`;
    const mailMessage = {
      to: "subteachersystem@gmail.com",
      subject: "פנייה של מועמד דרך צור קשר",
      text: content,
    };
    setFullName("")
    setEmail("")
    setPhone("")
    setPosition("")
    setMessage("")
    
    try {
      await axios.post("/api/request/send-mail", mailMessage);
      toast.success("המייל נשלח בהצלחה", {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

    } catch (error) {
      toast.error("הייתה בעיה בשליחת המייל נסה שוב מאוחר יותר", {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      //console.error("Error sending email:", error);
    }
  };

  return (
    <section className="section" id="contact">
      <div className="contact">
        <div className="contact--text">
          <p className="contact--text--title">דברו עם הצוות שלנו</p>
          <p className="contact--text--body">
            מוזמנים למלא פרטים ונחזור אליכם להדגמת מערכת ופרטים נוספים
          </p>
        </div>
        <form className="contactForm" action="" onSubmit={handleSendDetails}>
          <input
            type="text"
            name="fullName"
            onChange={(e) => setFullName(e.target.value)}
            id="fullName"
            placeholder="שם פרטי ומשפחה"
            value={fullName}
            required
          />
          <input
            type="email"
            name="email"
            onChange={(e) => setEmail(e.target.value)}
            id="email"
            placeholder="אימייל"
            value={email}
            required
          />
          <input
            type="phone"
            name="phone"
            onChange={(e) => setPhone(e.target.value)}
            id="phone"
            placeholder="טלפון"
            value={phone}
            required
          />
          <input
            type="text"
            name="position"
            onChange={(e) => setPosition(e.target.value)}
            id="position"
            placeholder="תפקיד ושם הארגון"
            value={position}
          />
          <input
            type="text"
            name="message"
            onChange={(e) => setMessage(e.target.value)}
            id="message"
            placeholder="הודעה"
            value={message}
          />
          <button type="submit" className="send">
            שליחה
          </button>
        </form>
      </div>
    </section>
  );
};

export default Footer;
