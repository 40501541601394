// DownloadButton.tsx

import React from "react";

interface DownloadButtonProps {
  fileName: string;
}

const DownloadButton: React.FC<DownloadButtonProps> = ({ fileName }) => {
  return (
    <a
      style={{ color: "#35788C", textDecoration: "none", fontWeight:"bold" }}
      href={`../../files/${fileName}`}
      target="_blank"
      rel="noopener noreferrer"
      
    >
      להורדת הטופס לחץ כאן
    </a>
  );
};

export default DownloadButton;
