import { useState } from "react";
import {
  FormInput,
  FormProvider,
  useAlert,
  useForm,
  useGetError,
  useUpdateOnSubmit,
  FormCheckBox,
  FormSelect,
} from "@hilma/forms";
import { useHasTwoFactorToken, useSendCode } from "@hilma/auth";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";

import { Button, EducationMultipleSelect } from "../components";
import { signUpRules } from "../constants";


const initialValues = {
  name: "",
  phone: "",
  email: "",
  gender: "",
  hasExperience: "",
  education: "",
  terms: false,
};

const SignUp: React.FC = () => {
  const [loading, setLoading] = useState(false);

  const alert = useAlert();

  const sendCode = useSendCode();
  const classes = useStyles();
  // const hasTwoFactorToken = useHasTwoFactorToken();
  const form = useForm();
  const navigate = useNavigate();
  const getError = useGetError();

  useUpdateOnSubmit(async (values) => {
    try {
      setLoading(true);
      const { success, msg } = await sendCode(
        "/api/candidate/send-code-to-new-candidate",
        {
          ...values,
          username: values.phone,
        }
      );
      if (!success) setLoading(false);
      if (!success && !msg.data?.message)
        alert("אין אפשרות לשלוח קוד. נא לנסות שוב מאוחר יותר");
      if (!success && msg.data?.message === "user already exists")
        alert("מספר הטלפון כבר קיים במערכת");
      if (success) navigate("/sign-in");
    } catch (error: any) {
      console.error(error);
      setLoading(false);
      alert(getError(error));
    }
  });

  return (
    <>
      {/* <ArrowBack className={classes.button} onClick={() => history.push('./sign-in')} /> */}
      <FormInput id="name" description="שם מלא" />

      <FormInput id="phone" description="טלפון" />
      <FormInput id="email" description="דואר אלקטרוני" />
      <FormSelect
        id="gender"
        description="מין"
        options={[
          { id: 1, value: "זכר" },
          { id: 2, value: "נקבה" },
        ]}
      />
      <FormSelect
        id="hasExperience"
        description=" - האם עבדת / עובד.ת כמורה במשרד החינוך
"
        options={[
          { id: 1, value: "כן" },
          { id: 2, value: "לא" },
        ]}
      />

      <EducationMultipleSelect />
      {/* <RadioGroupInput id='education' description="השכלה" options={candidateEducationsArray} /> */}
      <FormCheckBox
        id="terms"
        description={
          <div>
            קראתי את{" "}
            <a href="/terms-and-conditions" target="_blank">
              תנאי השימוש
            </a>
          </div>
        }
      />

      <Button
        className={classes.okButton}
        onClick={() => form.handleSubmit()}
        loading={loading}
      >
        המשך
      </Button>
      <Link to="/sign-in" className={classes.link}>
        יש לי כבר משתמש
      </Link>
    </>
  );
};

const SignUpComp: React.FC = () => (
  <FormProvider initialValues={initialValues} rules={signUpRules}>
    <SignUp />
  </FormProvider>
);
export default SignUpComp;

const useStyles = makeStyles((theme) => ({
  container: {
    paddingBottom: 20,
    height: "auto",
  },
  link: {
    color: theme.palette.primary.main,
    textAlign: "center",
    width: "fit-content",
    margin: "auto",
    marginTop: "1em",
    fontSize: "1.3rem",
  },
  okButton: {
    marginTop: 20,
  },
}));
