import React from "react";
import PageLink from "./PageLink";

interface PageLinksProps {
  parentClass: string;
  itemClass: string;
}

const PageLinks: React.FC<PageLinksProps> = ({ parentClass, itemClass }) => {
  const pageLinks = [
    { id: 1, href: "#howItWork", text: "איך זה עובד?" },
    {
      id: 2,
      href: "https://meyda.education.gov.il/files/Hashavut/tfasim/2-%20Shelonlmm.pdf",
      text: "פורטל עובדי הוראה",
    },
    { id: 3, href: "../../files/conditions.pdf", text: "תנאי העסקה" },
    { id: 4, href: "#contact", text: "צור קשר" },
  ];
  return (
    <ul className={parentClass} id="nav-links">
      {pageLinks.map((link) => (
        <PageLink
          key={link.id}
          link={link}
          itemClass={itemClass}
        />
      ))}
    </ul>
  );
};

export default PageLinks;
