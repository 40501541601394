import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import {
  Alert,
  AlertProvider,
  GoogleMapsProvider,
  I18nProvider,
  useFilesUpload,
} from "@hilma/forms";
import { AuthProvider, PrivateRoute, PublicOnlyRoute } from "@hilma/auth";
import { provide } from "@hilma/tools";

import { Home, SignIn, SignUpOrIn, TermsAndConditions } from "./routes";
import { Accessibility, AccessibilityLink } from "./components";
import { Background } from "./components";
import { muiTheme } from "./constants";

import "./stylesheets/index";
import { SM } from "./routes/SM";
import NewHome from "./components/HomeParts/NewHome";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect, useState } from "react";
import InstallPopup from './components/installpopup/InstallPopup';

function App() {
  useFilesUpload();
    const [installPrompt, setInstallPrompt] = useState<Event | null>(null);
    const [isMobile, setIsMobile] = useState(false);

    const handleInstallClick = () => {
        if (installPrompt instanceof Event) {
            const installEvent = installPrompt as any;
            installEvent.prompt();
            installEvent.userChoice.then((choiceResult: { outcome: string }) => {
                if (choiceResult.outcome === "accepted") {
                    console.log("User accepted the A2HS prompt");
                } else {
                    console.log("User dismissed the A2HS prompt");
                }
                setInstallPrompt(null);
            });
        }
    };

    const handleCloseClick = () => {
        setInstallPrompt(null);
    };

    // Effect to listen for the 'beforeinstallprompt' event
    useEffect(() => {
      console.log('Hello desktop')
        const handleBeforeInstallPrompt = (event: Event) => {
            event.preventDefault();
            setInstallPrompt(event);
        };

        window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

        return () => {
            window.removeEventListener(
                "beforeinstallprompt",
                handleBeforeInstallPrompt
            );
        };
    }, []);


     // Effect to check if the screen size is mobile
  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768); 
    };

    checkMobile(); 

    window.addEventListener("resize", checkMobile); 

    return () => {
      window.removeEventListener("resize", checkMobile); // Clean up on unmount
    };

  }, []);

  return (
    <I18nProvider router={false} muiPalette={muiTheme}>
      <AuthProvider
        logoutOnUnauthorized={true}
        accessTokenCookie="kloklokl"
        twoFactorCookie="lkoo"
      >
        <div
          className="App"
          dir="rtl"
          style={{ height: "100%", minHeight: "100vh" }}
        >
          <Background />
          <Routes>
            <Route
              path="/home"
              element={
                <PublicOnlyRoute component={NewHome} componentName="NewHome" />
              }
            />
            <Route path="/sm/:address/*" element={<SM />} />
            <Route
              path="/sign-in"
              element={
                <PublicOnlyRoute component={SignIn} componentName="SignIn" />
              }
            />
            <Route
              path="/sign-up"
              element={
                <PublicOnlyRoute
                  component={SignUpOrIn}
                  componentName="SignUp"
                />
              }
            />
            <Route
              path="/terms-and-conditions/*"
              element={<TermsAndConditions />}
            />
            <Route
              path="/*"
              element={
                <PrivateRoute
                  component={Home}
                  componentName="Home"
                  redirectPath="/home"
                />
              }
            />
            <Route
              path="/listOfRequests"
              element={
                <PublicOnlyRoute component={SignIn} componentName="SignIn" />
              }
            />
            <Route path="/accessibility/*" element={<Accessibility />} />
          </Routes>
          <Alert />
          <ToastContainer
            position="top-left"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
        </div>
      </AuthProvider>
      <AccessibilityLink />
        { isMobile && (
          <InstallPopup
            installPrompt={installPrompt}
            onInstallClick={handleInstallClick}
            onCloseClick={handleCloseClick}
     
        />
        )}
    </I18nProvider>
  );
}

export default provide(
  Router,
  AlertProvider,
  // [AuthProvider, {logoutOnUnauthorized: true, accessTokenCookie: "kloklokl", twoFactorCookie: "lkoo" }],
  GoogleMapsProvider
  // [I18nProvider, {router: false, muiPalette: muiTheme }]
)(App);




