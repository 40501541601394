import { useEffect } from 'react';
import { useForm } from '@hilma/forms';
import axios from 'axios';

import { useReject, useSetDisableNext, useSomeSelected, useUpdateSubmit, useUpdateSaved, useSubjects } from '../../hooks';
import { Container, FormCheckboxList } from '..';


const Sector: React.FC = () => {

	const subjects = useSubjects();
	const form = useForm();
	const setDisableNext = useSetDisableNext();
	const someSelected = useSomeSelected(form.values.subjects);
	const reject = useReject();
	const updateSaved = useUpdateSaved();

	const onSubmit = async () => {
		await form.setFieldValue('requiredValues', ['address', 'radius', 'daysOfWeek', 'sectors', 'grades', 'subjects']);

		form.handleSubmit();

		try {

			await axios.post('/api/candidate-pref/save-candidate-subjects', { subjects: form.values.subjects });

			await updateSaved();

		} catch (err: any) {
			return reject(err);
		}
	}

	useUpdateSubmit(onSubmit);


	useEffect(() => {
		if (someSelected) return setDisableNext(false);
		return setDisableNext(true);
	}, [someSelected]);

	return (
		<Container
			title='מקצועות'
			subTitle='אילו מקצועות מתאים לך ללמד?'
		>
			<FormCheckboxList id='subjects' options={subjects} />
		</Container>
	);
}

export default Sector;