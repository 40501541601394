import Navbar from "./NavBar";
import About from "./About";
import HowItWork from "./HowItWork";
import Footer from "./Footer";
import "../../stylesheets/homePage.css";


const NewHome: React.FC = () => {
  return (
    <div>
      <Navbar />
      <About />
      <HowItWork />
      <Footer />
      
    </div>
  );
};

export default NewHome;
