import { useCallback, ReactNode } from 'react';
import { Svg } from "@hilma/forms";

import { detailsSteps, iconGray, white } from "../../constants";
import { useCanShowCongratsFinished } from '../../hooks';
import { Checkbox } from "../../images";

interface IconSvgProps {
	icon: ReactNode;
	active: boolean | undefined;
	completed: boolean | undefined;
}

const IconSvg: React.FC<IconSvgProps> = ({ active, completed, icon }) => {

	const showCongratsFinished = useCanShowCongratsFinished();

	const getColor = useCallback(() => {
		return active ? white : iconGray;
	}, [active]);


	if (icon === 5 ? !showCongratsFinished && (active || !completed) : (!completed || active))
		return <Svg color={getColor()} svg={detailsSteps[Number(icon) - 1].icon} />

	return <Svg color={getColor()} svg={Checkbox} />

}

export default IconSvg;