export const signInErrorGenerator = (errorKey: string) => {
    switch (errorKey) {
        case 'Unauthorized': return 'כניסה לא מורשית';
        case 'UserIsBlocked': return 'מספר הטלפון נחסם';
        case 'MaxAttempts': return 'הגעת למספר המקסימלי של נסיונות';
        case 'CodeHasExpired': return 'קוד האימות כבר לא תקף';
        case 'IncorrectCode': return 'קוד האימות אינו תקין';
        case 'NoUsername': return 'מספר הטלפון לא נמצא במערכת';
        default: return '';
    }
}