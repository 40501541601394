import { useMemo } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';

import { useActiveStep, useDisableNext, useHandleNext, useHandlePrevious, useDetailsLoading, useShowWorkRegion, useCanShowCongratsFinished, useReusableStyles, useCandidateStatus } from '../../hooks';
import { NUMBER_OF_STEPS } from '../../constants';
import { UserStatus } from '../../types';
import { Button } from '..';

interface NextStepProps {
	onNext?: () => Promise<void>;
}

const NextStep: React.FC<NextStepProps> = ({ onNext }) => {

	const showCongratsFinished = useCanShowCongratsFinished();
	const candidateStatus = useCandidateStatus();
	const handlePrevious = useHandlePrevious();
	const showWorkRegion = useShowWorkRegion();
	const loading = useDetailsLoading();
	const disableNext = useDisableNext();
	const activeStep = useActiveStep();
	const handleNext = useHandleNext();

	const rClasses = useReusableStyles();
	const classes = useStyles();

	const onNextClick = async () => {
		if (onNext) return await onNext().catch(() => { });;
		await handleNext().catch(() => { });
	}

	const showBackButton = useMemo(() => !!activeStep || showWorkRegion, [activeStep, showWorkRegion]);


	if (showCongratsFinished) return null;

	return (
		<div className={clsx(classes.navigationContainer)}>

			<Button
				disabled={disableNext}
				className={clsx(classes.button, rClasses.button)}
				onClick={onNextClick}
				loading={loading}
			>
				{activeStep !== NUMBER_OF_STEPS - 1 ? 'המשך' : [UserStatus.Approved, UserStatus.Pending].includes(candidateStatus) ? 'עדכון' : 'שליחה'}
			</Button>

			<Button
				disabled={false}
				variant='text'
				className={clsx(classes.button, !showBackButton && classes.hideButton)}
				onClick={handlePrevious}
				loading={loading}
			>
				{showBackButton ? 'חזור' : <br />}
			</Button>

		</div>
	)
};


export default NextStep;

const useStyles = makeStyles({
	navigationContainer: {
		// position: 'fixed',
		// width: '78vw',
		// left: '11vw',
		// bottom: 15,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	},
	button: {
		// width: '100%',
		width: '78vw',

	},
	hideButton: {
		opacity: 0,
		pointerEvents: 'none'
	},
})