import makeStyles from "@material-ui/core/styles/makeStyles";

export const usePopupStyles = makeStyles(theme => ({

    container: {
        backgroundColor: "#fdfdfd",
        padding: "2em",
        color: theme.palette.primary.main
    },

    title: {
        fontWeight: "bold",
        fontSize: "1.6rem",
        marginBottom: "1em",
    },
    text: {
        fontSize: "1.3rem",
        marginBottom: "1.5em",
    },
    bottom: {
        display: "flex",
        justifyContent: "space-between"
    },

    cancel: {
        color: theme.palette.primary.main,
        borderRadius: "100px",
        borderColor: theme.palette.primary.main,
        boxShadow: "0px 3px 6px #00000029",
        fontSize: "18px",
        padding: "2px 20px"
    },

    button: {
        margin: '0 !important'
    }


}));