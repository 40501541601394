import { useMemo, useState } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import IconButton from "@material-ui/core/IconButton";
import { useLocation, useNavigate } from "react-router-dom";
import HomeIcon from "@material-ui/icons/Home";
import { useLogout } from "@hilma/auth";
import { useAlert } from "@hilma/forms";
import clsx from "clsx";

import { errors } from "../constants";

import { ConfirmPopup } from "./popup";
import Button from "./Button/Button";
import Logos from "./Logos";
import Delete from "./Delete";

const LogoAndLogOut: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [logOutPopup, setLogOutPopup] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const classes = useStyles();
  const logout = useLogout();
  const alert = useAlert();

  const hideHome = useMemo(
    () =>
      location.pathname === "/" || location.pathname === "/personal-details",
    [location]
  );

  const logOut = async () => {
    try {
      await logout();
      setLoading(false);
    } catch {
      alert(errors.generalSystem);
      setLoading(false);
    }
  };

  return (
    <div className={classes.container}>
      <Logos />
      <div className={classes.actions}>
        {!hideHome ? (
          <IconButton
            color="primary"
            onClick={() => navigate("/")}
            className={classes.homeButton}
            children={<HomeIcon className={classes.homeIcon} />}
          />
        ) : (
          <IconButton
            disabled
            children={
              <HomeIcon className={clsx(classes.homeIcon, classes.hideHome)} />
            }
          />
        )}
        <Button
          variant="outlined"
          blueLoading
          loading={loading}
          onClick={() => setLogOutPopup(true)}
          children="התנתק"
        />
        <Delete />
      </div>
      <ConfirmPopup
        open={logOutPopup}
        text="האם את\ה בטוח שברצונך להתנתק?"
        title="התנתקות"
        onClose={() => setLogOutPopup(false)}
        onConfirm={logOut}
      />
    </div>
  );
};

export default LogoAndLogOut;

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.up("sm")]: {
      width: "90%",
      margin: "auto",
      marginTop: 30,
      marginBottom: 15,
    },
    paddingLeft: "1em",
    paddingRight: "1em",
  },
  actions: {
    display: "flex",
    flexDirection: "row-reverse",
  },
  homeButton: {
    marginRight: 10,
  },
  homeIcon: {
    transform: "scale(1.3)",
  },
  hideHome: {
    color: "transparent",
  },
}));
