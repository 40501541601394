import { useHasTwoFactorToken } from "@hilma/auth";

import makeStyles from "@material-ui/core/styles/makeStyles";
import clsx from "clsx";
import { AppTitle, Logos, SignInCode, SignInPhone } from "../components";
import { useReusableStyles } from "../hooks";


const SignIn: React.FC = () => {

  const hasTwoFactorToken = useHasTwoFactorToken();
  const classes = useStyles();

  const rClasses = useReusableStyles();

  return (
    <div className={clsx(rClasses.container, classes.container)}>
      <div className={classes.loginHome}><a href="https://subteacher.co.il/home" className={classes.loginHomeLink}>
        <img className={classes.logo} src="/images/home-icon.png" alt="" />
      </a></div>
      <Logos hideTeacherLogo />
      <AppTitle showLogo />
      {!hasTwoFactorToken ? <SignInPhone /> : <SignInCode />}
    </div>
  );
};

export default SignIn;

export const useStyles = makeStyles((theme) => ({
  logo: {
    display: 'block',
    width: 60,
    [theme.breakpoints.down(600)]: {
      width: 40
    }
  },
  title: {
    color: theme.palette.primary.main,
    width: "100%",
    textAlign: "center",
    fontSize: "1.4rem",
  },
  loginHome:{
    position: 'absolute',
    top: '40px',
    left: '30px',
    [theme.breakpoints.down(600)]: {
      left: '20px',
    }
  },
  loginHomeLink: {
    color: theme.palette.primary.main,
    fontSize: '1.4rem',
    fontFamily: 'Assistant',
  },
  container: {
    "&>div:not([class])": {
      width: "52vw",
      margin: "0 auto",
    },
    "&>button": {
      [theme.breakpoints.up(600)]: {
        width: "fit-content",
        margin: "0 auto",
      },
    },
  },

  backButtonIconContainer: {
    marginLeft: "-12px",
    transform: "rotate(180deg)",
  },
}));
