import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { detailsSteps } from '../constants';

import { StepsContext, ActiveStepContext, UpdateActiveStepContext, HandleNextContext, HandlePreviousContext, SubmitRefContext, UpdateSavedContext, NextButtonTargetContext, DetailsLoadingContext, ShowWorkRegionContext, DisableNextContext, SetDisableNextContext, SetCanShowCongratsFinishedContext, CanShowCongratsFinishedContext, SetDetailsLoadingContext, UpdateVisitedContext } from '../context';
import { Icon, NumberedStep } from '../types';

export const useSteps = () => useContext(StepsContext)!;
export const useActiveStep = () => useContext(ActiveStepContext)!;
export const useUpdateActiveStep = () => useContext(UpdateActiveStepContext)!;
export const useHandleNext = () => useContext(HandleNextContext)!;
export const useHandlePrevious = () => useContext(HandlePreviousContext)!;
export const useSubmitRef = () => useContext(SubmitRefContext)!;
export const useUpdateSaved = () => useContext(UpdateSavedContext)!;
export const useNextButtonTarget = () => useContext(NextButtonTargetContext)!;
export const useDetailsLoading = () => useContext(DetailsLoadingContext)!;
export const useSetDetailsLoading = () => useContext(SetDetailsLoadingContext)!;
export const useShowWorkRegion = () => useContext(ShowWorkRegionContext)!;
export const useDisableNext = () => useContext(DisableNextContext)!;
export const useSetDisableNext = () => useContext(SetDisableNextContext)!;
export const useSetCanShowCongratsFinished = () => useContext(SetCanShowCongratsFinishedContext)!;
export const useCanShowCongratsFinished = () => useContext(CanShowCongratsFinishedContext)!;
export const useUpdateVisited = () => useContext(UpdateVisitedContext)!;

export const useNubmeredSteps = (): NumberedStep[] => {
	const steps = useSteps();

	const numberedSteps = useMemo<NumberedStep[]>(() => {
		return steps
			.map((step, index) => ({
				...step,
				index,
			}))
	}, [steps]);

	return numberedSteps;
}

export const useCurrentComponent = () => {
	return useCurrentComp('component');
}

export const useCurrentIcon = () => {
	return useCurrentComp('icon');
}

function useCurrentComp<T extends 'component' | 'icon'>(type: T): T extends 'icon' ? Icon : React.ComponentType<any>;
function useCurrentComp(s: 'component' | 'icon') {
	const activeStep = useActiveStep();

	const component = useMemo(() => detailsSteps[activeStep][s], [activeStep]);

	return component;
}

export const useUpdateSubmit = (submit: (isBackButton?: boolean) => Promise<void>) => {
	const submitRef = useSubmitRef();

	useEffect(() => {
		if (!submitRef) return;
		submitRef.current = submit;
	}, [submit]);

	useEffect(() => {
		if (!submitRef) return;
		return () => {
			submitRef.current = null;
		}
	}, []);
}

export const useActiveVisibleStep = () => {
	const activeStep = useActiveStep();
	const steps = useSteps();

	const activeVisibleStep = useMemo<number>(() => {
		const stepArray = steps.map((_, index) => index === activeStep ? 'activeStep' : 'otherStep');

		const activeVisibleStep = stepArray.indexOf('activeStep');

		return activeVisibleStep;
	}, [activeStep, steps]);

	return activeVisibleStep;
}

export const useSetActiveStep = () => {
	const updateActiveStep = useUpdateActiveStep();

	const setActiveStep = useCallback((step: number) => {
		updateActiveStep(async () => step);
	}, [updateActiveStep]);

	return setActiveStep;
}