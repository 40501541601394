import axios from 'axios';
import { FormInput, FormProvider, useAlert, useForm, useFormRules, useGetError } from '@hilma/forms';
import { Button } from '../general';
import { makeStyles } from '@material-ui/styles';
import { contactUsRules } from '../constants/rules/contectUsRules';
import { useNavigate } from 'react-router-dom';


interface ContactUsProps { }

const initialValues = {
    name: '',
    email: '',
    phone: '',
    title: '',
    content: ''
}

const ContactUs: React.FC<ContactUsProps> = () => {

    const form = useForm();
    const formRules = useFormRules();
    const classes = useStyles();
    const navigate = useNavigate()
    const alert = useAlert();
    const getError = useGetError();

    const onSubmit = async () => {
        try {
            form.handleSubmit();

            if (!form.isValid || !Object.values(form.touched).length) return;

            await axios.post('/api/admin/contactUs', { ...form.values });

            navigate("/")
        }
        catch (err: any) {
            console.error(err);
            alert(getError(err));
            return []
        }

    }

    return (
        <div className={classes.mainWrapper}>
            <h2 >צור קשר</h2>
            <form className={classes.contentWrapper} onSubmit={form.handleSubmit} >
                <FormInput style={{ backgroundColor: "white", borderColor: "lightGrey" }} id="name" description={"שם מלא" + '*'} />
                <FormInput style={{ backgroundColor: "white", borderColor: "lightGrey" }} id="email" type="email" dir="ltr" description={"כתובת אמייל" + '*'} />
                <FormInput style={{ backgroundColor: "white", borderColor: "lightGrey" }} id="phone" dir="ltr" description={"טלפון נייד" + '*'} />
                <FormInput style={{ backgroundColor: "white", borderColor: "lightGrey" }} id="title" description={"נושא"} />
                <FormInput
                    style={{ backgroundColor: "white", borderColor: "lightGrey" }}
                    id="content"
                    description={"איך אפשר לעזור" + '*'}
                    multiline
                    rows={4}
                    inputProps={{ maxLength: (formRules.content as any).smallerThan }}
                />
                <Button submit onClick={onSubmit}>שלח</Button>
            </form>
        </div>
    );
}

const ContactUsForm: React.FC<ContactUsProps> = (props) => (
    <FormProvider initialValues={initialValues} rules={contactUsRules}>
        <ContactUs {...props} />
    </FormProvider>
);
export default ContactUsForm;

const useStyles = makeStyles(({
    mainWrapper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "100%"
    },
    contentWrapper: {
        width: "40%",
        height: "80%",
        color: "white",

    },
    inputStyle: {
        backgroundColor: "white"
    }
}));