export const teal = '#35788C';
export const skyBlue = '#42BFDD';
export const white = '#F2F2F2';
export const error = '#FF2A2A';
export const backgroundGray = '#F2F2F2';
export const lightTeal = '#3D9FBB';
export const mediumGray = '#707070';
export const iconGray = '#00000029';
export const borderGray = '#C1C1C1';
export const boxShadowGray = ' #00000029';

export const darkBlue = '#03045E';
export const blue = '#0077B6';
export const brightSkyBlue = '#00B4D8';
export const lightBlue = '#90E0EF';
export const babyBlue = '#CAF0F8';