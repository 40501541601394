import { Sector, UploadFiles, Workdays, Location, Grade, Subject } from "../components/DetailsForm";
import { Calendar, Chat, Document, Map, Team, Cap, Book } from "../images";
import { Step } from '../types';

export const detailsSteps: Step[] = [
	{ icon: Map, component: Location, label: 'location', number: 0 },
	{ icon: Calendar, component: Workdays, label: 'workdays', number: 1 },
	{ icon: Team, component: Sector, label: 'sector', number: 2 },
	{ icon: Cap, component: Grade, label: 'grade', number: 3 },
	{ icon: Book, component: Subject, label: 'subject', number: 4 },
	{ icon: Document, component: UploadFiles, label: 'files', number: 5 },
];

export const NUMBER_OF_STEPS = detailsSteps.length;