import { useMemo, useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import Slide from '@material-ui/core/Slide';
import Chip from '@material-ui/core/Chip';
import { useAlert } from '@hilma/forms';
import moment from 'moment';
import axios from 'axios';
import clsx from 'clsx';

import { DaysOfWeek, RequestCandidate, RequestCandidateStatus, RequestCandidateTableType } from '../../types';
import { daysOfWeekObject, errors, grades, mediumGray, sectors } from '../../constants';
import { requestTimePassed } from '../../functions';
import { FormattedPhone } from '../../components';


import RequestActionByStatus from './RequestActionByStatus';

interface RequestRowProps {
    changeStatus(status: RequestCandidateStatus): void;
    type: RequestCandidateTableType;
    request: RequestCandidate;
    removeRow(): void;
    leaving: boolean;
};

const RequestRow: React.FC<RequestRowProps> = ({ request: { id, date, sector, time, note, name, address, subject, grade, status, phone, mainAdminName, mainAdminPhone, endTime, mainAdminEmail }, changeStatus, removeRow, leaving, type }) => {

    const [collapse, setCollapse] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const isMobile = useMediaQuery('(max-width: 600px)');
    const classes = useStyles();
    const alert = useAlert();

    const timeFormatted = useMemo(() => {
        const [t1, t2] = time.split(' - ');
        return `${t2} - ${t1}`
    }, []);
    const datePassed = useMemo(() => requestTimePassed(date, endTime), [date, endTime]);
    const dateText = useMemo(() => `יום ${daysOfWeekObject[new Date(date).getDay() as DaysOfWeek]} ${moment(date).format('DD.MM.YY')} | ${timeFormatted}`, [date, timeFormatted]);
    const noteText = useMemo(() => note ? note.split('\n').map((f, index) => index ? (<div key={index}>{f}</div>) : f) : 'אין הערות', [note]);
    const isLongNote = useMemo(() => note?.split('\n').filter(Boolean)?.length > 2, [note]);
    const dataArray = useMemo(() => [
        { label: 'מקצוע', value: subject },
        { label: 'כיתות', value: grades[grade] },
        { label: 'מגזר', value: sectors[sector] },
    ], [subject, grade, sector]);

    const onClick = async (newStatus: RequestCandidateStatus) => {
        try {
            if (loading) return;
            setLoading(true);
            if (!(type === RequestCandidateTableType.Denied && newStatus === RequestCandidateStatus.CandidateDenied)) //if its the denied table and the status is being changed to denied then nothing need to change
                await axios.post(`/api/request-candidate/update-request-status/${id}`, { newStatus });

            if (type === RequestCandidateTableType.Denied) {
                if (newStatus === RequestCandidateStatus.CandidateApproved) {
                    changeStatus(RequestCandidateStatus.CandidateApproved);
                    removeRow();
                } else if (newStatus === RequestCandidateStatus.CandidateDenied)
                    changeStatus(RequestCandidateStatus.CandidateDenied);
            } else
                switch (newStatus) {
                    case RequestCandidateStatus.CandidateApproved:
                        changeStatus(RequestCandidateStatus.CandidateApproved);
                        break;
                    case RequestCandidateStatus.CandidateApprovedFinal:
                    case RequestCandidateStatus.CandidateDeniedFinal:
                    case RequestCandidateStatus.CandidateDenied:
                        removeRow();
                        break;
                    default:
                        break;
                }

            alert(errors.suckupdated, 'success');
        } catch (error: any) {
            console.error('error: ', error);
            alert(error.data.message === 'deleted' ? errors.requestDeletedError : error.data.message === 'assigned' ? errors.requestAssignedError : errors.generalShort);
        } finally {
            setLoading(false);
        }
    }

    return (
        <div style={{ maxHeight: leaving ? 0 : 800, transition: leaving ? 'max-height 0.8s ease' : '' }} className={classes.container}>
            <Slide timeout={!(leaving) ? 0 : 500} in={!(leaving)} direction="right">
                <div className={clsx(classes.content, datePassed && classes.greyOut)}>
                    <div className={classes.colorGray}>
                        <div className={classes.titleContainer}>
                            <div className={classes.title}>
                                <div className={classes.bold}>{name} | {address}</div>
                                <div >{dateText}</div>
                            </div>
                            {datePassed && <Chip label="עבר" color="primary" classes={{ label: classes.datePassedText }} />}
                        </div>
                        <div className={clsx(classes.phoneContainer, datePassed && [classes.greyOut, classes.colorGray])}>
                            <div className={classes.phone}>טלפון אחראי: <FormattedPhone phone={mainAdminPhone} />, {mainAdminName}</div>
                            <div className={classes.phone}>טלפון בית הספר: <FormattedPhone phone={phone} /></div>
                            <div className={classes.phone}>מייל בית הספר: {mainAdminEmail}</div>
                        </div>
                        <div className={classes.requestDetails}>
                            {dataArray.map(({ label, value }, index) => (
                                <div key={index} ><span className={classes.semiBold}>{label}: </span>{value}</div>
                            ))}
                        </div>
                        <Collapse in={collapse || !isMobile} className={classes.collapse} collapsedSize={40}>
                            <div className={classes.note} onClick={() => (isMobile && isLongNote) && setCollapse(p => !p)}>
                                <div><span className={classes.semiBold}>הערות: </span>{noteText}</div>
                                {(!isMobile || !isLongNote) ? null : collapse ? <ExpandLess color="primary" /> : <ExpandMore color="primary" />}
                            </div>
                        </Collapse>
                    </div>
                    <div className={classes.buttons}>
                        <RequestActionByStatus changeStatus={changeStatus} loading={loading} onClick={onClick} status={status} datePassed={datePassed} />
                    </div>
                </div>
            </Slide>
        </div>
    )
};

export default RequestRow;

const useStyles = makeStyles(theme => ({
    container: {
        width: '90%',
        [theme.breakpoints.down('sm')]: {
            width: '98%'
        }
    },
    content: {
        padding: '20px 30px',
        borderRadius: 20,
        background: 'white',
        marginBottom: 20,
    },
    greyOut: {
        opacity: '0.7',
    },
    titleContainer: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    title: {
        color: theme.palette.primary.main,
        fontFamily: 'Assistant-Regular',
        alignItems: 'center',
        display: 'flex',
        gap: '3vw',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        }
    },
    bold: {
        fontFamily: 'Assistant-Bold',
        alignSelf: 'baseline',
        fontSize: '1.2rem',
    },
    semiBold: {
        fontFamily: 'Assistant-SemiBold',
    },
    colorGray: {
        color: `${mediumGray} !important`
    },
    requestDetails: {
        display: 'flex',
        marginTop: 20,
        gap: 20,
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            gap: 3
        }
    },
    collapse: {
        marginTop: 5,
        marginBottom: 5
    },
    note: {
        display: "flex",
        justifyContent: 'space-between',
    },
    buttons: {
        justifyContent: 'flex-end',
        alignItems: 'center',
        display: 'flex',
        marginTop: 5,
        gap: 10,
    },
    phone: {
        color: 'inherit',
        fontFamily: 'Assistant-Regular',
    },
    phoneContainer: {
        color: theme.palette.primary.main,
        marginTop: 20,
    },
    datePassedText: {
        fontFamily: 'Assistant-Bold'
    }
}));