import clsx from 'clsx';

interface ButtonLoadingProps {
    greenLoading?: boolean;
    className?: string;
}

const ButtonLoading: React.FC<ButtonLoadingProps> = ({ greenLoading, className }) => (
    <div className={clsx(className, "spinner", greenLoading && 'spinner--green')}>
        {[...Array(3)].map((_, index) => (
            <div key={index} className={`bounce${index + 1}`} />
        ))}
    </div>
);

export default ButtonLoading;