import { useCallback } from "react";
import { useNavigate, useLocation } from "react-router";
import makeStyles from "@material-ui/core/styles/makeStyles";
import EditIcon from '@material-ui/icons/Edit';

import { Button } from ".";


interface EditButtonProps {
	text?: string;
}

const EditButton: React.FC<EditButtonProps> = ({ text }) => {

	const classes = useStyles();
	const navigate = useNavigate();
	const location = useLocation();

	const goToEdit = useCallback(() => {

		if (location.pathname.match('personal-details/true')) return window.location.reload();
		if (location.pathname.match('personal-details')) {
			navigate('/personal-details/true', { replace: true });
			return window.location.reload();
		}

		navigate('/personal-details/true');

	}, [location, window]);

	return (
		<Button className={classes.button} onClick={goToEdit} variant='outlined'><EditIcon className={classes.editIcon} />{text || 'עריכת העדפות'}</Button>
	);
}

export default EditButton;

const useStyles = makeStyles({
	editIcon: {
		marginRight: 10,
	},
	button: {
		width: 'fit-content',
		margin: '0 auto'
	}
})