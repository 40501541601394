import React from "react";
import challengeImg from "../../images/challenge.jpg";
import solutionImg from "../../images/solution.jpg";
import logo from "../../images/applogo.png";
import userIcon from "../../images/userIcon.png";
import schoolIcon from "../../images/schoolIcon.png";

const About: React.FC = () => {
  return (
    <section className="section" id="about">
      <div className="hero">
        <div className="logo-container">
          <div className="logo--text">
            <p className="teacher">מורה</p>
            <p className="sub">להחלפה</p>
          </div>
          <img src={logo} alt="logo" className="logo"></img>
        </div>
        <div>
          <p className="logo--sub-text">
            המורה <span className="bold">המתאים ביותר</span> בזמן{" "}
            <span className="bold">הקצר ביותר.</span>
          </p>
        </div>
        <div className="loginAndSignUp">
          <div className="loginAndSignUp-content">
            <a href="https://subteacher.co.il/sign-in">
              <img className="signInLogo" src={userIcon} alt="teacher" />
            </a>
            <p>למערכת המורים</p>
          </div>
          <div className="loginAndSignUp-content">
            <a href="https://admin.subteacher.co.il/login">
              <img className="signInLogo" src={schoolIcon} alt="school" />
            </a>
            <p>למערכת בתי הספר</p>
          </div>
        </div>
      </div>
      <div className="closer">
        <p> פלטפורמה דיגיטאלית, המסייעת לבתי הספר לאתר מורות ומורים מחליפים <br/>
             <b>ומחברת בין מערכת החינוך העירונית לקהילה </b>
        </p>
      </div>
      <div className="gray">
        <p>
          המערכת פותחה במסגרת החטיבה לחדשנות טכנולוגית במשרד החינוך – Edstart
          ומאושרת בגפ"ן ובקטלוג החינוכי
        </p>
        {/*<p>*/}
        {/*  אתר <span>"מורה להחלפה"</span> מסייע לבתי הספר לאתר מורות ומורים*/}
        {/*  מחליפים המתאימים לשכבת הגיל ולמקצוע הלימוד.*/}
        {/*</p>*/}
        {/*<p>*/}
        {/*  האתר מתאם בזמן אמת בין בתי ספר שצריכים מורים להחלפה לבין מורים מתאימים*/}
        {/*  באזור שיכולים להחליף.*/}
        {/*</p>*/}
      </div>
      <div className="row row1">
        <div className="col">
          <img className="image" src={challengeImg} alt="teacher" />
        </div>
        <div className="col col__blue">
          <p className="col--title">האתגר</p>
          <p>
            על פי דוח מבקר המדינה יש בשנת לימודים, מעל 8,000,000 שעות היעדרות של
            מורים, המהווים כ 14% מכלל שעות הלימוד במהלך השנה. היעדרויות מורים
            פוגעת ברצף ואיכות ההוראה בבתי הספר ומובילה לירידה בהישגי התלמידים.
            בנוסף, היעדרות מורים גוזלת זמן יקר ממנהלי בתי הספר וגורמת לשחיקה
            בקרב צוות ההוראה בבית הספר.
          </p>
        </div>
      </div>
      <div className="row row2">
        <div className="col col__blue">
          <p className="col--title">הפתרון</p>
          <p>
            ״מורה להחלפה" הינה פלטפורמה דיגיטלית חדשנית הכוללת מאגר גדול ואיכותי
            של מורים מחליפים זמינים ומקצועיים, המאפשרת לבתי הספר לאתר בזמן קצר
            ובתפעול ידידותי, מורים מחליפים זמינים, המתאימים לשכבת הגיל ומקצוע
            הלימוד ולבחור את המורה המתאים ביותר על פי מידע הקיים במערכת הכולל:
            קורות החיים, תעודות, המלצות ותעודת יושר.
          </p>
        </div>
        <div className="col">
          <img className="image" src={solutionImg} alt="solution" />
        </div>
      </div>
    </section>
  );
};

export default About;
