import React, {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import MuiButton, {
  ButtonProps as MuiButtonProps,
} from "@material-ui/core/Button";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import useTheme from "@material-ui/core/styles/useTheme";
import createTheme from "@material-ui/core/styles/createTheme";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import { InputProps } from "@material-ui/core";
import clsx from "clsx";
import { useAsyncState, wrap } from "@hilma/tools";
import { useLanguage } from "@hilma/forms";

import { buttonScale, labelScale, skyBlue, teal, white } from "../../constants";

import ButtonLoading from "./ButtonLoading";

interface ButtonProps extends MuiButtonProps {
  submit?: boolean;
  to?: string;
  teal?: boolean;
  blueLoading?: boolean;
  loading?: boolean;
  dontSetLoadigTrue?: boolean;
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      submit,
      to,
      onClick,
      children,
      dontSetLoadigTrue,
      classes: clss = {},
      style = {},
      teal: lightGreen = false,
      blueLoading: greenLoading = false,
      loading: propsLoading,
      variant,
      disabled,
      ...rest
    },
    ref
  ) => {
    const [loading, setLoading] = useState(false);
    const [size, setSize] = useAsyncState<Record<
      "height" | "width",
      number
    > | null>(null);

    const buttonRef = useRef<HTMLButtonElement | null>(null);
    const mountedRef = useRef(true);
    const hoveredRef = useRef(false);
    const focusedRef = useRef(false);

    const classes = useStyles();

    const language = useLanguage();

    const navigate = useNavigate();

    const originalTheme = useTheme();

    const theme = useMemo(() => {
      const color = lightGreen ? teal : skyBlue;

      return createTheme({
        ...originalTheme,
        palette: {
          primary: {
            main: color,
            dark: color,
          },
        },
      });
    }, [lightGreen, originalTheme]);

    const handleClick = useCallback(
      async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (to) return navigate(`/${language}${to}`);
        event.preventDefault();
        if (!onClick || propsLoading || loading) return;
        !dontSetLoadigTrue && setLoading(true);
        await onClick(event);
        if (mountedRef.current) {
          setLoading(false);
          await setSize(null);
        }
      },
      [onClick, loading, to, propsLoading, language, dontSetLoadigTrue]
    );

    useEffect(() => {
      return () => {
        mountedRef.current = false;
      };
    }, []);

    const refFunction = (node: HTMLButtonElement | null) => {
      buttonRef.current = node;

      if (!ref) return;

      try {
        (ref as (instance: HTMLButtonElement | null) => void)(node);
      } catch (error) {
        try {
          (ref as React.MutableRefObject<HTMLButtonElement | null>).current =
            node;
        } catch (error) {}
      }
    };

    return (
      <ThemeProvider theme={theme}>
        <MuiButton
          ref={refFunction}
          color="primary"
          variant={variant || "contained"}
          onClick={handleClick}
          focusRipple={variant !== "text"}
          centerRipple
          TouchRippleProps={{ color: "#432" }}
          onMouseEnter={() => {
            hoveredRef.current = true;
          }}
          onMouseLeave={() => {
            hoveredRef.current = false;
          }}
          onFocus={() => {
            focusedRef.current = true;
          }}
          onBlur={() => {
            focusedRef.current = false;
          }}
          classes={{
            ...clss,
            root: clsx(
              classes.root,
              "custom-mui-button",
              clss.root,
              variant === "text" && classes.underlined,
              variant === "outlined" && classes.outlined,
              submit && classes.submitRoot,
              (propsLoading ?? loading) && classes.loadingButton
            ),
            label: clsx(
              classes.label,
              clss.label,
              variant === "text" && classes.underlinedLabel,
              variant === "outlined" && classes.outlinedLabel,
              submit && classes.submitLabel,
              lightGreen && classes.lightGreenLabel,
              disabled && classes.disabled
            ),
          }}
          style={size ? { ...style, ...size, cursor: "auto" } : style}
          {...rest}
          disabled={disabled}
        >
          {(propsLoading ?? loading) && variant !== "text" ? (
            <ButtonLoading greenLoading={greenLoading} />
          ) : (
            children
          )}
        </MuiButton>
      </ThemeProvider>
    );
  }
);

export default Button;

const useStyles = makeStyles((theme) => ({
  root: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    transition: "0.5s",
    cursor: "pointer",
    borderRadius: 100,
    padding: "5px 20px",
    textDecoration: "none !important",
    border: "none",
    outline: "none",
    height: "2.3rem",
    [theme.breakpoints.up(600)]: {
      width: "fit-content",
      margin: "0 auto",
    },

    "&:hover, &:focus": {
      transform: `scale(${buttonScale})`,
      "-webkit-transform": `scale(${buttonScale})`,
      "-moz-transform": `scale(${buttonScale})`,
      "-ms-transform": `scale(${buttonScale})`,
      "-o-transform": `scale(${buttonScale})`,

      "& span": {
        transform: `scale(${labelScale})`,
        "-webkit-transform": `scale(${labelScale})`,
        "-moz-transform": `scale(${labelScale})`,
        "-ms-transform": `scale(${labelScale})`,
        "-o-transform": `scale(${labelScale})`,
      } as CSSProperties,
    } as CSSProperties,
  },

  submitRoot: {
    minWidth: 130,
    margin: "auto",
    marginTop: 30,

    [theme.breakpoints.up(1600)]: {
      padding: "6px 22px",
      minWidth: 150,
    } as CSSProperties,
  },

  loadingButton: {
    pointerEvents: "none",
  },

  label: {
    textTransform: "none",
    transition: "0.5s",
    fontFamily: "Assistant-ExtraBold",
    fontSize: "1.2rem",
    color: white,
    [theme.breakpoints.up(1600)]: {
      fontSize: 20,
    } as CSSProperties,
    [theme.breakpoints.down(500)]: {
      fontSize: 18,
    } as CSSProperties,
  },

  underlinedLabel: {
    color: theme.palette.primary.main,
    textDecoration: "underline",
  },

  outlinedLabel: {
    color: theme.palette.primary.main,
    fontSize: "1.25rem",
    fontFamily: "Assistant-SemiBold",
    "&:hover, &:focus": {
      color: `${theme.palette.primary.main} !important`,
    },
  },

  submitLabel: {
    [theme.breakpoints.up(1600)]: {
      fontSize: 20,
    } as CSSProperties,
  },

  lightGreenLabel: {
    color: white,
  },

  underlined: {
    background: "none",
    border: "none",
    boxShadow: "none",
  },

  outlined: {
    border: `1px solid ${theme.palette.primary.main} !important`,
  },

  disabled: {
    opacity: "60%",
  },
}));
