import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import {
  FormInput,
  FormProvider,
  useAlert,
  useForm,
  useUpdateOnSubmit,
} from "@hilma/forms";
import { useHasTwoFactorToken, useSendCode } from "@hilma/auth";
import { provide } from "@hilma/tools";

import makeStyles from "@material-ui/core/styles/makeStyles";

import { useStyles as useSignInStyles } from "../routes/SignIn";
import { signInErrorGenerator } from "../functions";
import { loginPhoneRules } from "../constants";
import { Button } from ".";

import clsx from "clsx";

interface SignInPhoneProps {}

const initialValues = { phone: "" };

const SignInPhone: React.FC<SignInPhoneProps> = ({}) => {
  const [loading, setLoading] = useState(false);

  const sendCode = useSendCode();

  const form = useForm();

  const alert = useAlert();

  const classes = useStyles();
  const signInClasses = useSignInStyles();

  useUpdateOnSubmit(async (values) => {
    try {
      setLoading(true);
      const { success, msg } = await sendCode("/api/candidate/send-code", {
        username: values.phone,
      });
      if (!success) setLoading(false);
      if (!success && msg?.data.key) alert(signInErrorGenerator(msg.data.key));
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  });
  function handleEnter(event: any) {
    if (event.key === "Enter") {
      form.handleSubmit();
    }
  }

  useEffect(() => {
    document.addEventListener("keydown", handleEnter);
    return () => {
      document.removeEventListener("keydown", handleEnter);
    };
  }, []);

  return (
    <>
      <div className={signInClasses.title}>ברוכים הבאים!</div>
      <div className={classes.inputDiv}>
        <FormInput
          classes={{ input: classes.input }}
          type="tel"
          id="phone"
          description="מספר טלפון"
        />
      </div>
      <Button
        onClick={form.handleSubmit as () => void}
        type="submit"
        loading={loading}
      >
        שליחת קוד אימות
      </Button>

      <div className={classes.linkContainer}>
        <Link to="/sign-up" className={clsx(signInClasses.title, classes.link)}>
          רישום מורה להחלפה
        </Link>
        <span>|</span>
        <a
          className={clsx(signInClasses.title, classes.link)}
          href={`https://admin.subteacher.co.il/login`}
        >
          למערכת בתי הספר
        </a>
      </div>
    </>
  );
};

const SignInPhoneComp: React.FC<SignInPhoneProps> = (props) => (
  <FormProvider initialValues={initialValues} rules={loginPhoneRules}>
    <SignInPhone {...props} />
  </FormProvider>
);
export default SignInPhoneComp;

export const useStyles = makeStyles((theme) => ({
  linkContainer: {
    margin: "-1vh 0 0",
    width: "50%",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    color: theme.palette.primary.main,
    alignSelf: "center",
    fontSize: "1.4rem",
    [theme.breakpoints.down(800)]: {
      width: "93%",
    },
  },
  teacherLink: {
    marginTop: "1.5vh",
  },
  inputDiv: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  link: {
    // fontSize: '1.3rem',
    flex: "1",
    [theme.breakpoints.up(600)]: {
      marginTop: "8px",
    },
  },
  input: {
    width: "30vw",
    outline: "1px solid #808080",
  },
}));
