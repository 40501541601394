export const homePageText: Record<'pending' | 'approved' | 'denied', string[]> = {
	pending: [
		'המערכת שלנו תעבור על בקשתך להצטרפות למערכת',
		'בימים הקרובים תקבל/י הודעת אישור על הצטרפותך למערכת'
	],
	approved: [
		'שמחים על הצטרפותך למערכת מורה להחלפה',
		'ברגע שיהיו בקשות מתאימות נעדכן אותך'
	],
	denied: ['אנו מצטערים להודיעך כי מועמדותך לא אושרה.', 'לבירורים נוספים צור קשר עם המערכת.', 'subteachersystem@gmail.com']
}