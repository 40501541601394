import { useCallback } from "react";
import { useNavigate, useLocation } from "react-router";
import makeStyles from "@material-ui/core/styles/makeStyles";

import { Button } from ".";



const ContactUsButton: React.FC = () => {

	const classes = useStyles();
	const navigate = useNavigate();
	const location = useLocation();

	const goToContactUs = useCallback(() => {
		navigate('/contactUs');
	}, [location, window]);

	return (
		<Button className={classes.button} onClick={goToContactUs} variant='outlined'>{'צור קשר'}</Button>
	);
}

export default ContactUsButton;

const useStyles = makeStyles({
	button: {
		width: 'fit-content',
		margin: '0 auto'
	}
})