import { createRules, numbersRegex } from "@hilma/forms";
import { isString } from "class-validator";

export const personalDetailsRules = createRules({
  address: {
    required: true,
    classValidator: (value: { description: string }) =>
      value?.description ?? isString(value),
  },
  city: {
    required: true,
    classValidator: (name: { heCityName: string } | string) => {
      if (typeof name != "string") {
        name = name.heCityName;
      }
      return !!name;
    },
  },
  radius: {
    required: true,
    regex: numbersRegex,
    classValidator: (value: number) => value < 2147483647 && value > 4,
    activeRegexErr: "צריך מספר מ5 ומעלה",
  },
  image: { required: false },
  cv: { required: true, accept: ["application/pdf"] },
  policeCheck: { required: true, accept: ["application/pdf"] },
  experience: { required: true, accept: ["application/pdf"] },
  diploma: { required: false, accept: ["application/pdf"] },
  recommendation: { required: false, accept: ["application/pdf"] },
});
