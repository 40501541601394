import { useForm } from "@hilma/forms";
import axios from "axios";

import { useDetailsLoading, useReject, useUpdateSaved, useUpdateSubmit } from "../../hooks";
import { FormSlider, LocationAutocomplete, CitiesAutocomplete } from "..";
import { Container } from ".";

const Location: React.FC = () => {

	const form = useForm();
	const reject = useReject();
	const loading = useDetailsLoading();
	const updateSaved = useUpdateSaved();


	const submit = async () => {
		try {

			form.setFieldValue('requiredValues', ['address', 'radius', 'city']);

			form.handleSubmit();

			if (!form.isValid) return Promise.reject();

			const { data: { lat, lng } } = await axios.post('/api/candidate-pref/save-candidate-location', {
				address: form.values.address?.description ?? form.values.address,
				city: form.values?.city?.id,
				radius: form.values.radius
			});

			form.setFieldValue('lat', lat);
			form.setFieldValue('lng', lng);

			await updateSaved();

		} catch (err) {
			return reject(err);
		}
	}

	useUpdateSubmit(submit);

	return (
		<Container
			title='אזור העבודה'
			subTitle='על פי מקום המגורים שלך נגדיר את אזור העבודה'
			largeMargins
		>
			<CitiesAutocomplete
				id='city'
				description="עיר"
				loading={loading && !form.values?.city}
			/>

			<LocationAutocomplete
				address={form.values.address}
				id='address'
				description='כתובת'
			/>

			<FormSlider
				id='radius'
				minimum={5}
				description='מה מרחק העבודה אליו תגיע'
				helperText={`עד ${form.values.radius} ק"מ`}
			/>
		</Container>
	);
}

export default Location;
