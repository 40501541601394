import { Svg } from "@hilma/forms";
import makeStyles from "@material-ui/core/styles/makeStyles";
import clsx from 'clsx';

import { LogoAndLogOut, AppTitle, Button } from "../../components";
import { useReusableStyles, useCandidateName } from "../../hooks";
import { WaivingHand } from "../../images";
import { teal } from "../../constants";

interface BeginFormProps {
	setBeginForm: React.Dispatch<React.SetStateAction<boolean>>;
}

const BeginForm: React.FC<BeginFormProps> = ({ setBeginForm }) => {

	const classes = useStyles();
	const rClasses = useReusableStyles();
	const candidateName = useCandidateName();

	return (
		<div className={clsx(rClasses.container, classes.container)}>
			<LogoAndLogOut />

			<AppTitle />

			<Svg className={classes.svg} color={teal} svg={WaivingHand} />

			<div className={clsx(rClasses.reset, classes.helloText)}>
				<h3>שלום {candidateName},</h3>
				<h4>כדי לשלוח אליך את ההצעות המתאימות ביותר{<br />}נשמח לדעת עליך כמה פרטים</h4>
			</div>

			<Button className={classes.button} onClick={() => setBeginForm(true)}>הגדרת ההעדפות שלי</Button>

		</div>
	);
}

export default BeginForm;

const useStyles = makeStyles(theme => ({
	container: {
		[theme.breakpoints.down(600)]: {
			paddingBottom: '15vh'
		},
	},
	helloText: {
		textAlign: 'center',
		width: '100%',
		margin: '10px 0',
		'&>h3, &>h4': {
			all: 'inherit',
			fontSize: '1.3rem'
		}
	},
	svg: {
		margin: '0 auto',
	},
	button: {
		width: 'fit-content',
		margin: '0 auto'
	}
}));