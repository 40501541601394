import React from "react";
import systemImg from "../../images/school.jpg";
import systemImg2 from "../../images/school2.jpg";
import computerImg from "../../images/computer.jpeg";
import downArrowImg from "../../images/down-arrows.png";
import starImg from "../../images/star.png";
import YoutubeEmbed from "./YoutubeEmbed";
import userIcon from "../../images/userIcon.png";
import schoolIcon from "../../images/schoolIcon.png";

const HowItWork: React.FC = () => {
  return (
    <section className="section" id="howItWork">
      <div className="howItWork">
        <div className="section-title">
          <h1 style={{ color: "#31758e" }}>איך זה עובד?</h1>
        </div>
        <div className="how-it-work--row">
          <div className="how-it-work--col">
            <div className="image-container">
              <img src={systemImg} alt="teacher" className="how-it-work-img" />
              <div className="overlay">
                <div className="how-it-work--content">
                  <p className="col-title">בתי ספר</p>
                  <p>נרשמים כבית ספר באתר "מורה להחלפה"</p>
                  <img src={downArrowImg} alt="downArrowImg" />
                  <p>
                    ממלאים בקליק מודעת דרושים לשיעור הכוללת מועד, מקצוע ושכבת
                    גיל
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="how-it-work--col">
            <div className="image-container">
              <img src={systemImg2} alt="school" className="how-it-work-img" />
              <div className="overlay">
                <div className="how-it-work--content">
                  <p className="col-title">מורים</p>
                  <p>נרשמים כמורים באתר "מורה להחלפה"</p>
                  <img src={downArrowImg} alt="downArrowImg" />
                  <p>
                    ממלאים בקליק פרטים אישיים, מקצועות ההוראה, ימים פנויים וטווח
                    גאוגרפי למילוי מקום
                  </p>
                  <img src={downArrowImg} alt="downArrowImg" />
                  <p>מוסיפים קורות חיים, תעודות, המלצות ותעודת יושר</p>
                </div>
              </div>
            </div>
          </div>
          <div className="how-it-work--col">
            <div className="image-container">
              <img src={systemImg2} alt="system" className="how-it-work-img" />
              <div className="overlay">
                <div className="how-it-work--content">
                  <p className="col-title">המערכת</p>
                  <p>
                    המערכת מאתרת את המורים המחליפים התואמים את מקצוע הלימוד
                    והטווח הגאוגרפי ושולחת למורה התראה עם פרטי המודעה
                  </p>
                  <img src={downArrowImg} alt="downArrowImg" />

                  <p>
                    מורה פנוי ומעוניין לבצע את ההחלפה, מאשר לבית הספר בקליק שהוא
                    מעוניין
                  </p>
                  <img src={downArrowImg} alt="downArrowImg" />

                  <p>
                    בית הספר בוחר את המורה המתאים לו ביותר מבין המורים שאישרו
                    ומאשר את ההחלפה.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="note">
          <img src={starImg} alt="star" />
          המערכת שומרת את המידע ומאפשרת לצפות בהסטוריית השיעורים שהוחלפו במערכת
          בית הספר ובממשק המורה.
        </div>
        <div className="how-it-work-videos">
          <div className="how-it-work-video">
            <p>ממשק בתי ספר</p>
            <YoutubeEmbed embedId="YtPND7ZizfA" startTime={53} />
          </div>
          <div className="how-it-work-video">
            <p>ממשק מורים</p>
            <YoutubeEmbed embedId="-RYHrqWH6IU" />
          </div>
        </div>
        <div className="row row2">
          <div className="col col__blue">
            <p>
              ניהול המאגר הארצי של בתי הספר והמורים המקצועיים מופעל ע"י צוות
              מיזם "מורה להחלפה" ורישומם למערכת נעשה באופן מבוקר ובאישור של
              הצוות.
            </p>
          </div>
          <div className="col">
            <img className="image" src={computerImg} alt="solution" />
          </div>
        </div>
        <div className="closer">
          <div className="loginAndSignUp">
            <div className="loginAndSignUp-content">
              <a href="https://subteacher.co.il/sign-in">
                <img className="signInLogo" src={userIcon} alt="teacher" />
              </a>
              <p>למערכת המורים</p>
            </div>
            <div className="loginAndSignUp-content">
              <a href="https://admin.subteacher.co.il/login">
                <img className="signInLogo" src={schoolIcon} alt="school" />
              </a>
              <p>למערכת בתי הספר</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HowItWork;
