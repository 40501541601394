import { useAlert } from '@hilma/forms';
import { generateError } from '../functions';

const useReject = () => {
	const alert = useAlert();

	return (err: any) => {

		alert(generateError(err));

		console.error(err);

		return Promise.reject(err);

	}
}

export default useReject;