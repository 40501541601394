import { makeStyles } from "@material-ui/core";
import { useNavigate } from "react-router";
import { Button } from "../components";
import { backgroundGray, teal } from "../constants";

interface PageNotFoundProps {

}

const PageNotFound: React.FC<PageNotFoundProps> = ({ }) => {

	const classes = useStyles();
	const navigate = useNavigate();

	return (
		<div className={classes.container} >
			<div className={classes.text}>
				<div className={classes.text}>אופס! העמוד המבוקש לא נמצא</div>
				<div className={classes.homeBtnContainer}>
					<Button onClick={() => navigate('/')}>לעמוד הבית</Button>
				</div>
			</div>
		</div >
	)
}


export default PageNotFound


const useStyles = makeStyles({
	container: {
		position: 'relative',
		top: '25vh',
		display: 'flex',
		justifyContent: 'center',
	},
	text: {
		background: backgroundGray,
		color: teal,
		fontSize: '6vw',
		paddingBottom: '5vh',
		paddingTop: '7vh',
		paddingLeft: '3vh',
		paddingRight: '3vh',
		width: 'fit-content',
		height: 'fit-content',
	},
	homeBtnContainer: {
		display: 'flex',
		justifyContent: 'center',
		paddingBottom: '7vh',
	}
});