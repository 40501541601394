import React, { useState } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useAsyncEffect } from "@hilma/tools";
import { useAlert } from "@hilma/forms";
import produce from "immer";
import axios from "axios";

import { RequestCandidate, RequestCandidateStatus, RequestCandidateTableType } from "../../types";
import { errors, teal } from "../../constants";

import RequestRow from "./RequestRow";

const RequestTable: React.FC<{ type: RequestCandidateTableType }> = ({ type }) => {
  const [requests, setRequests] = useState<RequestCandidate[]>([]);
  const [leaving, setLeaving] = useState<number | null>(null);
  const [loading, setLoading] = useState(false);

  const classes = useStyles();
  const alert = useAlert();

  useAsyncEffect(async () => {
    try {
      setLoading(true);
      const requestList = await axios.get("/api/request-candidate/get-candidate-requests", { params: { type } });
      setRequests(requestList.data);
    } catch (error) {
      console.error("Error: ", error);
      alert(errors.getDataError);
    } finally {
      setLoading(false);
    }
  }, []);

  const changeRowStatus = (index: number, status: RequestCandidateStatus) => {
    setRequests(produce(draft => {
      draft[index].status = status;
    }));
  }

  const removeRow = (index: number) => {
    setLeaving(index);
    setTimeout(() => {
      setLeaving(null);
      setRequests((prev) => prev?.filter((_, i) => index !== i));
    }, 800);
  }

  return (
    <div className={classes.scrollViewWrapper}>
      {loading ? (
        <div className={classes.loadingContainer}>
          <CircularProgress />
        </div>
      ) : requests.length == 0 ? (
        <div className={classes.noFound} >לא נמצאו קריאות</div>
      ) : (requests?.map((r: RequestCandidate, index: number) => (
        <RequestRow
          key={r.id}
          request={r}
          type={type}
          removeRow={() => removeRow(index)}
          leaving={leaving === index}
          changeStatus={(status: RequestCandidateStatus) => changeRowStatus(index, status)}
        />
      )))}
    </div>
  );
};

export default RequestTable;

const useStyles = makeStyles(({
  loadingContainer: {
    alignContent: "center",
    alignSelf: "center",
  },
  scrollViewWrapper: {
    flexDirection: "column",
    justifySelf: "center",
    alignItems: "center",
    display: "flex",
  },
  noFound: {
    fontWeight: 'bold',
    fontSize: "1.2em",
    marginTop: 10,
    color: teal,
  }
}));
