import { useState } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useNavigate, useLocation } from 'react-router-dom';
import { default as TabsMui } from '@material-ui/core/Tabs';
import TabContext from '@material-ui/lab/TabContext';
import TabPanel from '@material-ui/lab/TabPanel';
import Tab from '@material-ui/core/Tab';

import { teal } from '../constants';
import { TabsTab } from '../types';

const Tabs: React.FC<{ tabs: TabsTab[] }> = ({ tabs = [] }) => {

    const classes = useStyles();

    const location = useLocation();
    const navigate = useNavigate();

    const [value, setValue] = useState<`${number}` | undefined>(location.search ? location.search.substring(1) as `${number}` : '0');

    const handleChange = async (_: React.ChangeEvent<{}>, newValue: number) => {
        navigate({
            pathname: location.pathname,
            search: newValue.toString()
        });
        setValue(`${newValue}`);
    };

    return (
        <div className={classes.Tabs} >
            {/* tabs */}
            {value && <TabContext value={value}>
                <div className={classes.tabTabs}>
                    <TabsMui indicatorColor="primary"
                        className={classes.tabs}
                        textColor="primary"
                        value={value}
                        onChange={handleChange} >
                        {tabs?.map((tab, index: number) => {
                            const { label, render, ...rest } = tab
                            return (<Tab
                                className={classes.tabs}
                                key={index}
                                value={index.toString()}
                                label={label}
                                {...rest}
                            />)
                        })}
                    </TabsMui>
                </div>
                {tabs?.map((tab, index: number) =>
                    <TabPanel
                        key={index}
                        value={index.toString()}>
                        {tab.render}
                    </TabPanel>
                )}
            </TabContext>}
        </div>
    )
};

export default Tabs;

//styling
const useStyles = makeStyles({
    tabs: {
        color: teal,
        fontFamily: 'Assistant-Regular',
        textTransform: 'none',
        '&.Mui-selected': {
            fontFamily: 'Assistant-Bold'
        }
    },
    Tabs: {
        width: '85vw',
        margin: 'auto'
    },
    tabTabs: {
        borderBottom: `1px solid ${teal}40`,
        width: '75vw',
        margin: 'auto'
    },
});