import makeStyles from "@material-ui/core/styles/makeStyles";

import { Title, LogoAndLogOut, Tabs } from "../../components";
import { RequestCandidateTableType } from "../../types";

import RequestTable from "./RequestTable";

const ListOfRequests: React.FC = () => {

  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.info}>
        <LogoAndLogOut />
        <Title>הקריאות שלי:</Title>
      </div>
      <Tabs tabs={[
        { render: <RequestTable type={RequestCandidateTableType.Pending} />, label: 'הצעה לקריאות' },
        { render: <RequestTable type={RequestCandidateTableType.Approved} />, label: 'קריאות מאושרות' },
        { render: <RequestTable type={RequestCandidateTableType.Denied} />, label: 'קריאות שלא אושרו' },
      ]} />
    </div>
  );
};

export default ListOfRequests;

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: '4vh',
  },
  info: {
    flex: 1,
    flexGrow: 1,
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    color: theme.palette.primary.main,
    fontSize: "1.1rem",
    textAlign: "center",
    gap: 18,
    marginBottom: "3vh",
  },
}));
