import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';

interface FormHelperTextProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
	text?: string | null;
	error?: boolean;
}

const FormHelperText: React.FC<FormHelperTextProps> = ({ className, text, error = false, ...rest }) => {
	const classes = useStyles();

	return (
		<div
			{...rest}
			className={clsx(classes.root, error && classes.error, className)}
			children={text}
		/>
	);
}

const useStyles = makeStyles(theme => ({
	root: {
		color: theme.palette.primary.main,
		[theme.breakpoints.up(500)]: {
			height: 15,
			fontSize: 11,
		},
		[theme.breakpoints.down(500)]: {
			height: 18,
			fontSize: 14,
		},
		[theme.breakpoints.up(1600)]: {
			height: 20,
			fontSize: 16,
		},
		textAlign: 'left',
	},

	error: {
		color: theme.palette.error.main,
	}
}))

export default FormHelperText;
