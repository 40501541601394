import { useState } from "react";
import { useAlert } from "@hilma/forms";
import { useLogout } from "@hilma/auth";
import axios from "axios";

import { errors } from "../constants";

import { ConfirmPopup } from "./popup";
import Button from "./Button/Button";

const Delete: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [deleteUserPopup, setDeleteUserPopup] = useState(false);
  const logout = useLogout();

  const logOut = async () => {
    try {
      await logout();
      setLoading(false);
    } catch {
      alert(errors.generalSystem);
      setLoading(false);
    }
  };
  const alert = useAlert();

  const deleteCandidate = async () => {
    try {
      await axios.delete(`/api/candidate/delete-candidate`);
      setLoading(false);
      setDeleteUserPopup(false)
      await logout();
      
    } catch {
      alert(errors.generalSystem);
      setLoading(false);
    }
  };

  return (
    <div>
      <Button
        variant="outlined"
        blueLoading
        loading={loading}
        onClick={() => setDeleteUserPopup(true)}
        children="מחיקת משתמש"
        style={{ marginLeft: "1rem" }}
      />
      <ConfirmPopup
        open={deleteUserPopup}
        text="האם את\ה בטוח שברצונך למחוק את המשתמש?"
        title="מחיקת משתמש"
        onClose={() => setDeleteUserPopup(false)}
        onConfirm={deleteCandidate}
      />
    </div>
  );
};

export default Delete;
