import React, {useState} from "react";
import PageLinks from "./PageLinks";
import DropDown from "./DropDown";

const Navbar: React.FC = () => {
  return (
    <nav className="navbarHome">
      <div className="links-container">
        <DropDown />
        <PageLinks
          parentClass="nav-links"
          itemClass="nav-link"
        />
      </div>
    </nav>
  );
};

export default Navbar;
