import { useState } from 'react';
import Button, { ButtonProps } from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';

import ButtonLoading from '../general/button/ButtonLoading';
import { ConfirmPopup } from ".";

interface PopupButtonProps extends ButtonProps {
    popupText?: string | JSX.Element,
    isSecondary?: boolean,
    loading?: boolean,
};

const PopupButton: React.FC<PopupButtonProps> = ({ isSecondary, loading, popupText, children, onClick, ...rest }) => {

    const [popup, setPopup] = useState<boolean>(false);
    const classes = useStyles();
    return (
        <>
            <Button
                variant='contained'
                className={classes.approvedOrDeniedButton}
                color={isSecondary ? "secondary" : "primary"}
                onClick={loading ? undefined : popupText ? () => setPopup(true) : onClick}
                children={<>
                    {loading && <ButtonLoading className={classes.loadingCircles} />}
                    <div className={clsx(loading && classes.loadingText)} style={{ fontFamily: 'Assistant-Bold' }}>{children}</div>
                </>}
                {...rest}
            />
            {popupText && <ConfirmPopup
                open={popup}
                text={popupText}
                title="שינוי סטטוס קריאה"
                onClose={() => setPopup(false)}
                onConfirm={onClick as () => Promise<void>}
            />}
        </>
    )
};

export default PopupButton;

const useStyles = makeStyles(theme => ({
    approvedOrDeniedButton: {
        fontFamily: 'Assistant-Bold',
        borderRadius: 100,
        color: 'white',
        [theme.breakpoints.down('sm')]: {
            fontSize: '12px'
        }
    },
    loadingText: {
        color: 'transparent',
    },
    loadingCircles: {
        position: 'absolute',
    }
}));