import makeStyles from "@material-ui/core/styles/makeStyles";

export const useSuccessfulSubstituteStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: "#fdfdfd",
    minWidth: "70vw",
    overflow: "hidden",
    color: theme.palette.primary.main,
  },

  xButton: {
    color: theme.palette.primary.main,
  },
  innerXIcon: {
    fontSize: "2.6em",
  },
  title: {
    fontFamily: "Assistant-Bold",
    fontSize: "1.5rem",
    padding: 20,
    paddingTop: 0,
    display: "flex",
    justifyContent: "center",
  },
  hr: {
    width: "100%",
    opacity: "0.5",
  },
  acceptorName: {
    fontFamily: "Assistant-Bold",
    fontSize: "2rem",
    paddingTop: "1em",
    display: "flex",
    justifyContent: "center",
  },
  requestInfo: {
    fontFamily: "Assistant-Regular",
    fontSize: "1.5rem",
    paddingBottom: "2em",
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
  },
  bottom: {
    display: "flex",
    justifyContent: "space-between",
  },
}));
