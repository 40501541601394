import React from "react";
// import "./homePage.css";

interface PageLinkProps {
  link: {
    id: number;
    href: string;
    text: string;
  };
  itemClass: string;
}

const PageLink: React.FC<PageLinkProps> = ({ link, itemClass }) => {
  return (
    <li key={link.id}>
      <a
        href={link.href}
        className={itemClass}
        download={link.id === 3 ? "/files/conditions.pdf" : undefined}
      >
        {link.text}
      </a>
    </li>
  );
};

export default PageLink;
